import '../App.css';
import React,{useEffect, useState} from "react";
import logo from '../Assets/Images/new_logo.png';
import { useNavigate } from "react-router-dom";
import toast,{Toaster} from "react-hot-toast";
import md5 from "md5";
import { getToken, getUser, setUserSession } from '../Helpers/auth-helper';
import { postData } from '../Helpers/api-helper';

import eyeOffIcon from '../Assets/Icons/eye-off-fill.png'
import eyeIcon from '../Assets/Icons/eye-fill.png'
import Loader from '../Components/Loader';
import { Modal } from 'reactstrap';
import { requestForToken } from '../Components/Firebase';

function Login(props){
  const [showPassword,setShowPassword] = useState(false)
  const [loading,setLoading] = useState(false)
  const [username,setUsername] = useState("")
  const [password,setPassword] = useState("")

	const navigate = useNavigate();

  useEffect(() => {
    // const data_user = getUser();
    // console.log(data_user)
    // if(data_user){
    //   setUsername(data_user.user_name)
    //   setPassword(data_user.password)
    //   submitLogin()
    // }
  },[])

    const validateLogin = ()=>{
        setLoading(true);
        if(!username && username == ""){
            toast.error("User ID harus diisi!");
            console.log("User ID harus diisi!")
            setLoading(false)
        } else if(!password && password == ""){
            toast.error("Password harus diisi!");
            console.log("Password harus diisi!")
            setLoading(false)
        }else{
            submitLogin()
        }
    }

    const submitLogin= async ()=>{
        var token = getToken()
        let dataOptions = {access_token: token, user_name: username, 
          password: md5(password), login_from: process.env.REACT_APP_VERSION};

        const response = await postData(process.env.REACT_APP_BACKEND_URL+'/login',dataOptions)

        if (response) {
          setLoading(false)
          if (response?.data?.success == 1) {
            console.log('successMessage', response.data.data[0]);
            var stored_user_id = response.data.data[0].id;
            var stored_name = response.data.data[0].name;
            var stored_user_image = response.data.data[0].image;
            var stored_role_id = response.data.data[0].role_id;
            var stored_position_id = response.data.data[0].position_id;
            var stored_role = response.data.data[0].role;
            var stored_position = response.data.data[0].position;
            var max_location_distance = response.data.data[0].max_location_distance ?? 5;
            var check_location_interval = response.data.data[0].check_location_interval ?? 60000;
            var role_for_promotor = response.data.data[0].role_for_promotor ?? "PROMOTOR";
            var stored_location_free = response.data.data[0].location_free ?? 0;
            var system_identity_key = response.data.data[0].system_identity_key;

            localStorage.setItem('max_location_distance', max_location_distance)
            localStorage.setItem('check_location_interval', check_location_interval)
            localStorage.setItem('role_for_promotor', role_for_promotor)
            localStorage.setItem('stored_location_free', stored_location_free)
            localStorage.setItem('system_identity_key', system_identity_key)
            requestForToken(stored_user_id)

            let userObject = {
              user_id: stored_user_id,
              user_name: username,
              password: password,
              name: stored_name,
              user_image: stored_user_image,
              role_id: stored_role_id,
              position_id: stored_position_id,
              role: stored_role,
              position: stored_position,
              system_identity_key:system_identity_key
            };
            setUserSession(userObject);
            navigate('/')
          } else if (response?.data?.success == 0) {
            var resultMessage = response?.data?.message?.toString();
            console.log('resultMessage', resultMessage);
            var errorCode = resultMessage.substring(0, 4);
            console.log(errorCode);
            if (errorCode == "1121") {
              toast.error('User ID atau Password salah');
            } else if (errorCode == "1122") {
              toast.error('Anda sudah diberhentikan');
            } else if (errorCode == "1123") {
              toast.error('User ID ini sudah terdaftar menggunakan perangkat lain');
            } else {
              toast.error(resultMessage);
            }
          } else {
            toast.error('Gagal masuk ke aplikasi, silakan dicoba lagi');
          }
      }else{
        
        setLoading(false)
      }
    }

    return(
        <div className='login-background'>
            <Loader loading={loading} timeOut={60}/>
           
            <div className='login-logo'>
                <img src={logo} width={"80%"} style={{marginTop:0,verticalAlign:'middle'}}/>
            </div>
            <h2 style={{color:"white"}}>New ARM Mobile Versi {process.env.REACT_APP_VERSION}</h2>
            <div className='login-form'>
                <input type='text' className='form-input-login' placeholder='User Name' onChange={(e) => setUsername(e.target.value)}/>
                <div class="wrapper-form">
                    <div class="icon-form" onClick={() => setShowPassword(!showPassword)}><img src={showPassword ? eyeOffIcon : eyeIcon} width={20} height={20}/></div>
                    <input class="input-form-icon" type={showPassword ? 'text' : 'password'} className='form-input-login' placeholder='Password' onChange={(e) => setPassword(e.target.value)}/>
                </div>  
                <button className='btn-login' onClick={() => validateLogin()}><b>MASUK</b></button>
                <button className='btn-ubah-password' onClick={() => navigate('/ubah-password')}><b>Ubah Password</b></button>
            </div>
        </div>
    )
}

export default Login