import React, { useEffect, useState } from "react";
import { getToken, getUser, removeSession } from "../../Helpers/auth-helper";

import RenderSidebar from "../../Components/Sidebar";
import Footer from "../../Components/Footer";
import { Navigate, useLocation, useNavigate, useNavigationType } from "react-router-dom";
import useSWR from "swr";
import axios from "axios";
import toast from 'react-hot-toast'
import { getData } from "../../Helpers/api-helper";

const ProtectedRoute = ({ children }) => {
	const user = getUser();
    const [collapsed, setCollapsed] = useState(true);
	// console.log("world value",api_version)
	const api_version = GettingVersion();
	useEffect(() => {
		api_version.then((res) => 
			{
				if(res !== process.env.REACT_APP_VERSION){
					toast('System Updating '+res);
					caches.delete()
					caches.keys().then(function(cacheNames) {
						cacheNames.forEach(function(cacheName) {
							caches.delete(cacheName);
						});
					});
					window.location.reload(true);
					console.log("Changing Version to", api_version)
				}
			}
		)

	},[api_version])

    const navigate = useNavigate();

    const location = useLocation();
    const navType = useNavigationType();
	const [current_location,setCurrentLocation] = useState()
	
	const camera_permit = sessionStorage.getItem('camera_permission')
	const location_permit = sessionStorage.getItem('location_permission')


    useEffect(() => {
		setCurrentLocation(location.pathname)
        if (navType === "POP" && location.key !== "default" && current_location == '/outlet') {
            navigate('/')
        }

		if(location_permit == 'no' || camera_permit == 'no'){
			if(location_permit == 'no'){
				toast('Izinkan penggunaan lokasi/gps untuk menggunakan aplikasi')
				sessionStorage.removeItem('location_permission')
			}
			if(camera_permit == 'no'){
				toast('Izinkan penggunaan kamera untuk menggunakan aplikasi')
				sessionStorage.removeItem('camera_permission')
			}
			removeSession()
			navigate('/login')
		}
      }, [ navigate ])
	  
	// if(user && permission == '1'){
	if(user){

		return (
			<>
				<RenderSidebar params={children.props} collapsed={collapsed} setCollapsed={setCollapsed}/>
				<div className='main-page' onClick={() => setCollapsed(true)}>
					{children}
				</div>
				{children.props?.footer && <Footer/>}
			</>
		);
	}
	if (!user) {
		return <Navigate to="/login" replace={true} />;
	}
};

const GettingVersion =async()=>{
	
	const access_token = getToken()
	const response = await getData(process.env.REACT_APP_BACKEND_URL+'/get_parameter_by_key?param_key=PWA_VERSION')
	if (response) {
		if (response?.data) {
			return response?.data.parameter[0].param_value;
		} else {
			return null
		}
	}
	// const fetcher = async (url, params) => {
	// 	return await axios({method: 'get', url: url, params: params,
	// 	headers: 
	// 	{
	// 		'Authorization': `Bearer ${access_token}` 
	// 	}}).then(res => res.data)
	// }

	// const { data } = useSWR(() => process.env.REACT_APP_BACKEND_URL+'/get_parameter_by_key?param_key=PWA_VERSION', fetcher)

	// // console.log("SWR data: ", data)

	// return {
	// 	api_version:data?.data[0].value ? data?.data[0].value : process.env.REACT_APP_VERSION
	// }
}

export default ProtectedRoute;
