import { Navigate } from "react-router-dom";
import { getUser } from "../../Helpers/auth-helper";

const GuestRoute = ({ children }) => {
	const data_user = getUser();

	if (data_user) {
		return <Navigate to="/" replace />;
	}

	return children;
};

export default GuestRoute;
