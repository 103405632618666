import '../../App.css';
import React,{useEffect, useRef, useState, useCallback} from "react";
import { useNavigate,useLocation } from "react-router-dom";
import backIcon from '../../Assets/Icons/back.png';
import toast,{ Toaster } from 'react-hot-toast';
import Webcam from 'react-webcam';
import { Modal } from 'reactstrap';
import { format } from "date-fns";

import defaultPic from '../../Assets/Images/default_pic.png';
import starIcon from '../../Assets/Icons/star-p.png';
import folderIcon from '../../Assets/Icons/folder-p.png';
import walletIcon from '../../Assets/Icons/wallet-p.png';
import { getOutletId, getToken, getUser } from '../../Helpers/auth-helper';
import { getData, postData, postImage } from '../../Helpers/api-helper';
import Loader from '../../Components/Loader';
import capturePic from '../../Assets/Images/capture.png';
import flipPic from '../../Assets/Images/flip.png';
import addImagePic from '../../Assets/Images/add_image_gallery.png';
import addImagePCP from '../../Assets/Images/add-pcp.png';
import deletePCP from '../../Assets/Images/delete-pcp.png';

function DetailPerbandinganHarga(){
    
  const fileInputRef=useRef();
  const webcamRef = useRef(null);
    const data_user = getUser()
	const navigate = useNavigate();
    const {state} = useLocation();
    const {item,transaction_type,outlet_transaction_id,outlet_visit_id} = state;

    const [order_number,set_order_number] = useState(item?.order_number ?? 1);
    const [latest_order_number,set_latest_order_number] = useState(item?.order_number ?? 1);
    const [loading, setLoading] = useState(false);
    const [timeOut, setTimeOut] = useState(process.env.REACT_APP_BACKEND_URL/1000);
    const [price, setPrice] = useState(item?.price != null ? Math.round(item?.price) : null);
    const [price_promo, setPricePromo] = useState(item?.price_promo != null ? Math.round(item?.price_promo) : null);
    const [notes, setNotes] = useState(item?.notes ?? null);
    const [modalVisible, setModalVisible] = useState(false);
    const [imageURI, setImageURI] = useState(item?.image ?? null);
    const [imageName, setImageName] = useState('');
    const [imageFile, setImageFile] = useState('');
    const [modalCamera, setModalCamera] = useState(false);
    const [cameraPosition, switchCamera] = useState(true);
    const [show_data, set_show_data] = useState(item?.order_number ?? false);
    const [data_list,set_data_list] = useState([]);
    const videoConstraints = {
      width: 640,
      height: 480,
        facingMode: { exact: cameraPosition ? 'environment' : 'user' },
    };
  
    const refreshView = () => {
    //   global.activeScreen = 'PriceComparisonScreen';
    //   console.log('Opening PriceComparisonScreen');
    //   navigation.setOptions({title: 'Detail Harga'});
    }
  
    useEffect(() => {
        refreshView();
        getProductPriceComparison()
    }, []);

    const getProductPriceComparison=async()=>{
      setLoading(true)
      var user_id = data_user?.user_id ? data_user?.user_id : data_user?.id
      let dataOptions = {product_id: item?.product_id, 
        reference_id: outlet_visit_id === undefined ? 
        item?.outlet_transaction_id : outlet_visit_id, 
        user_id: user_id,
        reference_type: outlet_visit_id === undefined ? 'NOTVIS' : 'OUTVIS', outlet_id: item?.outlet_id};

      // setTimeOut(global.timeOut/1000)
      // setLoading(true)
      const response = await getData(process.env.REACT_APP_BACKEND_URL+'/get_product_price_comparison', dataOptions)
      setLoading(false)

      if (response) {
          if (response?.data.length > 0) {
              set_data_list(response.data)
              set_show_data(true)
              console.log('raw order',Math.max.apply(Math, response.data.map(function(o) { return o.order_number; })))
              set_latest_order_number(Math.max.apply(Math, response.data.map(function(o) { return o.order_number; })))
          } else {
              // var resultMessage = response?.data?.message?.toString();
              // console.log('resultMessage', resultMessage);
              // toast.error(resultMessage);
          }
      }
    }
  
    const handleSubmitButton = async () => {
      const imageResponse = await sendImage()
        var image = imageResponse
        if (imageResponse === false) {
          image = ''
        }
        const access_token = getToken()
        var user_id = data_user?.user_id ? data_user?.user_id : data_user?.id
      let dataOptions = {access_token: access_token, product_id: item?.product_id, 
        reference_id: outlet_visit_id === undefined ? 
        item?.outlet_transaction_id : outlet_visit_id, 
        user_id: user_id,
        reference_type: outlet_visit_id === undefined ? 'NOTVIS' : 'OUTVIS', 
        transaction_type: global?.transaction_type ? global?.transaction_type : transaction_type, outlet_id: item?.outlet_id, 
        price: removeDot(price),price_promo: removeDot(price_promo),image:image, notes: notes,  order_number:order_number,
        created_by: user_id, entry_date:item?.entry_date};
  
        setLoading(true)
        const response = await postData(process.env.REACT_APP_BACKEND_URL+'/insert_product_price_comparison', dataOptions)
  
        if (response) {
          setLoading(false)
          if (response?.data?.success == 1) {
            toast.success('Data sudah tersimpan');
            // navigate(-1)
            getProductPriceComparison()
            set_show_data(true)
          } else {
            var resultMessage = response?.data?.message?.toString();
            console.log('resultMessage', resultMessage);
            toast.error(resultMessage);
          }
        }else{
          setLoading(false)
        }
    };
  
    const addThousandSparator = (num) => {
      if (num != null) {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      } else {
        return null;
      }
    }
    const removeNonNumeric = (num) => {
      if (num != null) {
        return num.toString().replace(/[^0-9]/g, "");
      } else {
        return null;
      }
    }
    const removeDot = (num) => {
      if (num != null) {
        return num.toString().replace(/[.]/g, '');
      } else {
        return null;
      }
    }

    const numberFormatter = new Intl.NumberFormat('id-ID', {
        style: 'decimal',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    });

    const takeAPic = useCallback(() => {
      const imageSrc = webcamRef.current.getScreenshot();
      var imageName = new Date().getTime()+".jpg";
      console.log(imageName)
      setImageURI(imageSrc)
      
      setImageName(format(new Date(), "yyyyMMdd")+imageName)
      setModalCamera(false)
      setModalVisible(false)
    }, [webcamRef, setImageURI]);

    const sendImage = async () => {
      // setTimeOut(global.imageTimeOut/1000)
      // setLoading(true)
        if (imageURI != '' && imageURI != null) {
            let response_img = await fetch(imageURI);
            let data = await response_img.blob();
            let metadata = {
                type: 'image/jpeg'
            };
            let new_file = new File([data], imageName, metadata);
            const response = await postImage(new_file,imageName)
            setLoading(false)
        
            if (response) {
                if (response?.data?.success == 1) {
                    var new_pic = process.env.REACT_APP_API_URL + '/' + process.env.REACT_APP_API_FOLDER + '/' + process.env.REACT_APP_BINARY_FOLDER + '/' + 
                    imageName.substring(0,4) + '/' + imageName.substring(4,6) + '/' 
                    + imageName.substring(6,8) + '/' + imageName;
                    return new_pic
                } else {
                var resultMessage = response?.data?.message?.toString();
                console.log('resultMessage', response);
                    if (resultMessage !== "File sudah ada di server") {
                        toast.error(resultMessage);
                        return false
                    } else {
                        var new_pic = process.env.REACT_APP_API_URL + '/' + process.env.REACT_APP_API_FOLDER + '/' + process.env.REACT_APP_BINARY_FOLDER + '/' + 
                        imageName.substring(0,4) + '/' + imageName.substring(4,6) + '/' 
                        + imageName.substring(6,8) + '/' + imageName;
                        return new_pic
                    }
                }
            } else {
                return false
            }
        }else{
            return ''
        }
    }

    const createNewData=()=>{
      setImageURI(null)
      setPrice(null)
      setPricePromo(null)
      setNotes(null)
      set_show_data(false)
      set_order_number(latest_order_number+1)
    }

    const DeletePCP=async(data)=>{
      setLoading(true)
      var user_id = data.user_id ? data.user_id : data.id
      let dataOptions = {product_id: data.product_id, 
        reference_id: outlet_visit_id === undefined ? 
        data.outlet_transaction_id : outlet_visit_id, 
        user_id: user_id,
        reference_type: outlet_visit_id === undefined ? 'NOTVIS' : 'OUTVIS', outlet_id: data.outlet_id, order_number:data.order_number, entry_date:data.entry_date};

      // setTimeOut(global.timeOut/1000)
      // setLoading(true)
      const response = await getData(process.env.REACT_APP_BACKEND_URL+'/delete_product_price_comparison', dataOptions)
      setLoading(false)

      if (response) {
          if (response?.data) {
            getProductPriceComparison()
            toast.success('Hapus data berhasil')
          } else {
              var resultMessage = response?.data?.message?.toString();
              console.log('resultMessage', resultMessage);
              toast.error(resultMessage);
          }
      }
    }

    if(show_data){
      return(
        <div className='page-background'>
          <Loader loading={loading} timeOut={60}/>
          <div className='detail-page'>
            <div className='list-images'>
              {
                data_list && data_list.map((data)=>
                  <div className='add-image'>
                      <img onClick={() => DeletePCP(data)} src={deletePCP} width={50} height={50} className='delete-item-pcp'/>
                      <img onClick={() => {
                      set_show_data(false)
                      set_order_number(data.order_number)
                      setPrice(Math.round(data?.price))
                      setPricePromo(Math.round(data?.price_promo))
                      setNotes(data.notes)
                      setImageURI(data.image)
                      }} src={data.image} className='item-image' width={'100%'} height={150}/>
                  </div>
                )
              }
              <div className='add-image'>
                  <img onClick={() => createNewData()} src={addImagePCP} className='item-image' width={'100%'} height={150}/>
              </div>
            </div>
          </div>
        </div>
      )
    }else{
      return(
          <div className='page-background'>
          <Loader loading={loading} timeOut={60}/>
              <div className='detail-page'>
                  <div className='cards' style={{backgroundColor:"#fafafa",width:"100%",borderRadius:10}}>
                      <div className='d-flex'>
                          <img className='card-img-product' src={defaultPic} width={90} height={90}/>
                          <div className='card-text'>
                              <div className='card-title'>
                                  {item?.product}
                              </div>
                              <div className='card-desc' style={{fontWeight:600}}>
                                  {item?.storage_type}
                              </div>
                              <div className='card-item' style={{height:20}}>
                                  <img src={walletIcon} width={18} height={18}/>
                                  <p style={{fontWeight:700}}>
                                      {'Rp' + numberFormatter.format(item?.price_in_a_unit)}
                                  </p>
                              </div>
                              <div className='card-icons-2 d-flex' style={{marginRight:10}}>
                                  <div style={{marginRight:'auto'}}>

                                  </div>
                                  <div className='card-item'>
                                      <img src={folderIcon} width={18} height={18}/>
                                      <p style={{fontWeight:700}}>{item?.brand}</p>
                                  </div>
                                  <div className='card-item'>
                                      <img src={starIcon} width={18} height={18}/>
                                      <p style={{fontWeight:700}}>{item?.category}</p>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className=''>
                    &nbsp;
                      <div className='add-image'>
                          <img onClick={() => {
                              setModalVisible(true)
                              // startCamera()
                              }} src={imageURI ? imageURI : addImagePic} className='add-image-button' width={'100%'} height={150}/>
                      </div>
                      <input className='form-input-3' type='text' placeholder='Harga Produk Normal' value={addThousandSparator(price)} onChange={(e) => setPrice(addThousandSparator(removeNonNumeric(e.target.value)))}/>
                      <input className='form-input-3' type='text' placeholder='Harga Produk Promo' value={addThousandSparator(price_promo)} onChange={(e) => setPricePromo(addThousandSparator(removeNonNumeric(e.target.value)))}/>
                      <textarea className='form-textarea-1' placeholder='Catatan' value={notes} onChange={(e) => setNotes(e.target.value)}/>
                  </div>
                  <button className='outlet-checkout-button' onClick={() => handleSubmitButton()}>SIMPAN</button>
              </div>
              <Modal isOpen={modalVisible} onClosed={() => setModalVisible(false)} centered toggle={() => setModalVisible(false)} className='modal-gambar'>
                      <button className='btn-red' onClick={() => fileInputRef.current.click()}>FOTO DARI GALERI</button>
                      <input
                          ref={fileInputRef}
                          type="file"
                          name="imageURI"
                          onChange={(event) => {
                              console.log(event.target.files[0])
                              setImageURI(URL.createObjectURL(event.target.files[0]))
                              setImageFile(event.target.files[0])
                              setImageName(format(new Date(), "yyyyMMdd")+event.target.files[0].name)
                              setModalVisible(false)
                          }}
                          style={{display:'none'}}
                      />
                      <button className='btn-red' onClick={() => setModalCamera(true)}>FOTO DARI KAMERA</button>
                      <Modal isOpen={modalCamera} onClosed={() => setModalCamera(false)} centered toggle={() => setModalCamera(false)} fullscreen className='modal-gambar'>
                          <Webcam
                              audio={false}
                              ref={webcamRef}
                              screenshotFormat="image/jpeg"
                              videoConstraints={videoConstraints}
                              minScreenshotWidth={640}
                              minScreenshotHeight={'100%'}
                              screenshotQuality={1}
                              label="wow"
                              style={{
                              position: "absolute",
                              zindex: 888,
                              left:0,
                              right:0,
                              height: "90vh",
                              width: "100%",
                              objectFit: "cover",
                              }}
                          />
                          <div className='group-camera-buttons'>
                              <div className='camera-button' onClick={() => setModalCamera(false)}>
                                  <p style={{color:"black"}}>BATAL</p>
                              </div>
                              <div className='camera-button'>
                                  <img onClick={takeAPic} src={capturePic} width={70} height={70}/>
                              </div>
                              <div className='camera-button'>
                                  <img onClick={() => switchCamera(!cameraPosition)} src={flipPic} width={110} height={60}/>
                              </div>
                          </div>
                      </Modal>
              </Modal>
          </div>
      )
    }
}

export default DetailPerbandinganHarga