import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import '../src/Helpers/config'
import {Toaster} from 'react-hot-toast'

require('react-web-vector-icons/fonts');

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <>
        <Toaster position='bottom-center'/>
        <App />
    </>
);

serviceWorkerRegistration.register();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();