import './App.css';
import React,{useEffect, useState} from "react";
import { RouterProvider, useNavigate } from "react-router-dom";
import routes from "./Routes/web";
import { getUser,setUserSession,getToken, removeSession } from './Helpers/auth-helper';
import toast from 'react-hot-toast';
import md5 from 'md5';
import { postData } from './Helpers/api-helper';
import Loader from './Components/Loader';
import { checkLocation } from './Components/Permissions';
import headImg from './Assets/Splash/Header-baru-01.png';
import logoImg from './Assets/Splash/new_logo.png';
import FootImg from './Assets/Splash/Footer-baru-02.png';
import Foot2Img from './Assets/Splash/Foot2.png';
import { requestForToken } from './Components/Firebase';
import Notification from './Components/NotificationFirebase';

function App() {
    const [loading,setLoading]= useState(false)
    const [splash,setSplash]= useState(false)
    // console.log("world value",api_version)
    useOnceCall(() => {
      var call = checkFirstLoad();
      console.log(call,"call")
      if(call == 'load_first'){
        // InitOneSignal()
        setSplash(true)
        setTimeout(() => {
          setSplash(false)
        },2500)
        var data_user = getUser()
        var user_id = data_user?.user_id ? data_user?.user_id : data_user?.id
        if(data_user){
          submitLogin()
          requestForToken(user_id)
        }
      }
    })

    const checkFirstLoad=()=>{
      var first_load = sessionStorage.getItem('first_load')
      if(first_load && first_load == 'yes'){
        return 'load_not_first'
      }else{
        return 'load_first'
      }
    }

    const submitLogin= async ()=>{
      var token = getToken()
      var data_user = getUser()
      let dataOptions = {access_token: token, user_name: data_user.user_name, 
        password: md5(data_user.password), login_from: process.env.REACT_APP_VERSION};

      const response = await postData(process.env.REACT_APP_BACKEND_URL+'/login',dataOptions)

      if (response) {
        setLoading(false)
        if (response?.data?.success == 1) {
          sessionStorage.setItem('first_load','yes')
            var stored_user_id = response.data.data[0].id;
            var stored_name = response.data.data[0].name;
            var stored_user_image = response.data.data[0].image;
            var stored_role_id = response.data.data[0].role_id;
            var stored_position_id = response.data.data[0].position_id;
            var stored_role = response.data.data[0].role;
            var stored_position = response.data.data[0].position;
            var max_location_distance = response.data.data[0].max_location_distance ?? 5;
            var check_location_interval = response.data.data[0].check_location_interval ?? 60000;
            var role_for_promotor = response.data.data[0].role_for_promotor ?? "PROMOTOR";
            var stored_location_free = response.data.data[0].location_free ?? 0;
            var system_identity_key = response.data.data[0].system_identity_key;

            localStorage.setItem('max_location_distance', max_location_distance)
            localStorage.setItem('check_location_interval', check_location_interval)
            localStorage.setItem('role_for_promotor', role_for_promotor)
            localStorage.setItem('stored_location_free', stored_location_free)
            localStorage.setItem('system_identity_key', system_identity_key)

            let userObject = {
              user_id: stored_user_id,
              user_name: data_user.user_name,
              password: data_user.password,
              name: stored_name,
              user_image: stored_user_image,
              role_id: stored_role_id,
              position_id: stored_position_id,
              role: stored_role,
              position: stored_position,
              system_identity_key:system_identity_key
            };
            setUserSession(userObject);
        } else if (response?.data?.success == 0) {
          var resultMessage = response?.data?.message?.toString();
          console.log('resultMessage', resultMessage);
          var errorCode = resultMessage.substring(0, 4);
          console.log(errorCode);
          if (errorCode == "1121") {
            toast.error('User ID atau Password salah');
          } else if (errorCode == "1122") {
            toast.error('Anda sudah diberhentikan');
          } else if (errorCode == "1123") {
            toast.error('User ID ini sudah terdaftar menggunakan perangkat lain');
          } else {
            toast.error(resultMessage);
          }
        } else {
          toast.error('Gagal masuk ke aplikasi, silakan dicoba lagi');
        }
    }else{
      
      setLoading(false)
    }
  }

  if(splash){
    return(
      <div className="SplashScreen">
        <div>
        <img src={headImg} style={{objectFit:"contain"}} width={"100%"}/>
        </div>
        <div style={{width:"40%",margin:"auto",height:200,display:"grid",justifyContent:"center",alignItems:"center",alignContent:"center"}}>
        <img src={logoImg} style={{objectFit:"contain"}} width={"100%"}/>
        </div>
        <div>
          <b style={{position:"absolute",bottom:40,textAlign:"center",left:0,right:0}}>New ARM Mobile Versi {process.env.REACT_APP_VERSION}</b>
        <img src={Foot2Img} style={{position:"absolute",bottom:0}} width={"100%"}/>
        <img src={FootImg} style={{position:"absolute",bottom:0}} width={"100%"}/>
        </div>
    </div>
    )
  }else{
    return (
      <div className="App">
        <Notification/>
        <Loader loading={loading} timeOut={60}/>
          <RouterProvider router={routes} fallbackElement={<div>Loading</div>} />
      </div>
    );
  }
}

function useOnceCall(cb, condition = true) {
  const isCalledRef = React.useRef(false);

  useEffect(() => {
    if (condition && !isCalledRef.current) {
      isCalledRef.current = true;
      cb();
    }
  }, [cb, condition]);
}

export default App;
