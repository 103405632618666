import { createBrowserRouter, createHashRouter } from "react-router-dom";

import GuestRoute from "./middleware/guest";
// import ProtectedRoute from "./middleware/protected";

import Login from "../Pages/Login";
import UbahPassword from "../Pages/UbahPassword";
import Outlet from "../Pages/Outlet/Outlet"

import Error from "../Pages/Errors/Error";
import ProtectedRoute from "./middleware/protected";
// import StokOutlet from "../Pages/Outlet/StokOutlet";
import DetailStok from "../Pages/Outlet/DetailStok";
import DetailPerbandinganHarga from "../Pages/Outlet/DetailPerbandinganHarga";
import RiwayatKunjungan from "../Pages/Mainpage/Riwayat/RiwayatKunjungan";
import Beranda from "../Pages/Mainpage/Beranda";
import Riwayat from "../Pages/Mainpage/Riwayat";
import Kepegawaian from "../Pages/Kepegawaian/Kepegawaian";
import DetailKepegawaian from "../Pages/Kepegawaian/DetailKepegawaian";
import DaftarOutlet from "../Pages/Outlet/DaftarOutlet";
import TambahOutlet from "../Pages/Outlet/TambahOutlet";
import RiwayatKepegawaian from "../Pages/Mainpage/Riwayat/RiwayatKepegawaian";
import DetailPemesananDanPenjualan from "../Pages/Outlet/DetailPemesananDanPenjualan";
import PemesananDanPenjualan from "../Pages/Outlet/PemesananDanPenjualan";
import KameraCheck from "../Pages/Outlet/KameraCheck";
import Survey from "../Pages/Outlet/Survey";
import KameraTest from "../Pages/Outlet/KameraTest";
import Dashboard from "../Pages/Mainpage/Dashboard";
import RegistrasiOutlet from "../Pages/Outlet/Registrasi";
import RiwayatApproval from "../Pages/Mainpage/Riwayat/RiwayatApproval";

const routeList = [
    {
		errorElement: process.env.NODE_ENV === "development" ? undefined : <Error />,
        children : [
            //guest
			{
				path: "/login",
				exact: true,
				element: (
					<GuestRoute>
						<Login title={'Login'} auth={false}/>
					</GuestRoute>
				),
			},
			{
				path: "/ubah-password",
				exact: false,
				element: (
					<GuestRoute>
						<UbahPassword title={'Ubah Password'} auth={false}/>
					</GuestRoute>
				),
			},
            //authorize
			{
				path: "/",
				exact: false,
				element: (
					<ProtectedRoute>
						<Beranda title={'Beranda'} auth={true} mainpage={true} footer={true}/>
					</ProtectedRoute>
				),
			},
			{
				path: "/riwayat",
				exact: false,
				element: (
					<ProtectedRoute>
						<Riwayat title={'Riwayat'} auth={true} mainpage={true} footer={true}/>
					</ProtectedRoute>
				),
			},
			{
				path: "/riwayat/kunjungan",
				exact: false,
				element: (
					<ProtectedRoute>
						<RiwayatKunjungan title={'Kunjungan'} auth={true} footer={true}/>
					</ProtectedRoute>
				),
			},
			{
				path: "/riwayat/kepegawaian",
				exact: false,
				element: (
					<ProtectedRoute>
						<RiwayatKepegawaian title={'Kepegawaian'} auth={true} footer={true}/>
					</ProtectedRoute>
				),
			},
			{
				path: "/riwayat/approval-outlet",
				exact: false,
				element: (
					<ProtectedRoute>
						<RiwayatApproval title={'Approval'} auth={true} footer={true}/>
					</ProtectedRoute>
				),
			},
			{
				path: "/outlet/registrasi",
				exact: false,
				element: (
					<ProtectedRoute>
						<RegistrasiOutlet title={'Registrasi Outlet'} auth={true} back={'/'}/>
					</ProtectedRoute>
				),
			},
			{
				path: "/outlet",
				exact: false,
				element: (
					<ProtectedRoute>
						<Outlet title={'Outlet'} auth={true} share={true} back={'/'}/>
					</ProtectedRoute>
				),
			},
			{
				path: "/outlet/survey",
				exact: false,
				element: (
					<ProtectedRoute>
						<Survey title={'Survey'} auth={false} back={'outlet'}/>
					</ProtectedRoute>
				),
			},
			// {
			// 	path: "/outlet/stok",
			// 	exact: false,
			// 	element: (
			// 		<ProtectedRoute>
			// 			<StokOutlet title={'Stok di toko'} auth={true}/>
			// 		</ProtectedRoute>
			// 	),
			// },
			{
				path: "/outlet/detail-stok",
				exact: false,
				element: (
					<ProtectedRoute>
						<DetailStok title={'Detail stok'} auth={true}/>
					</ProtectedRoute>
				),
			},
			{
				path: "/outlet/detail-perbandingan-harga",
				exact: false,
				element: (
					<ProtectedRoute>
						<DetailPerbandinganHarga title={'Detail Harga'} auth={true}/>
					</ProtectedRoute>
				),
			},
			{
				path: "/outlet/list",
				exact: false,
				element: (
					<ProtectedRoute>
						<DaftarOutlet title={'Daftar Outlet'} auth={true}/>
					</ProtectedRoute>
				),
			},
			{
				path: "/outlet/tambah",
				exact: false,
				element: (
					<ProtectedRoute>
						<TambahOutlet title={'dynamic'} auth={true}/>
					</ProtectedRoute>
				),
			},
			{
				path: "/outlet/pemesanan",
				exact: false,
				element: (
					<ProtectedRoute>
						<PemesananDanPenjualan title={'dynamic'} auth={true}/>
					</ProtectedRoute>
				),
			},
			{
				path: "/outlet/pemesanan/detail",
				exact: false,
				element: (
					<ProtectedRoute>
						<DetailPemesananDanPenjualan title={'dynamic-detail'} auth={true}/>
					</ProtectedRoute>
				),
			},
			{
				path: "/outlet/kamera",
				exact: false,
				element: (
					<ProtectedRoute>
						<KameraCheck title={'Kamera Check'} auth={true} headless={true}/>
					</ProtectedRoute>
				),
			},
			{
				path: "/outlet/kamera/test",
				exact: false,
				element: (
					<GuestRoute>
						{/* <KameraCheck title={'Kamera Check'} auth={true} headless={true}/> */}
						<KameraTest title={'Kamera Check'} auth={true} headless={true}/>
					</GuestRoute>
				),
			},
			{
				path: "/kepegawaian",
				exact: false,
				element: (
					<ProtectedRoute>
						<Kepegawaian title={'Kepegawaian'} auth={true} mainpage={true}/>
					</ProtectedRoute>
				),
			},
			{
				path: "/kepegawaian/detail",
				exact: false,
				element: (
					<ProtectedRoute>
						<DetailKepegawaian title={'HRDetail'} auth={true}/>
					</ProtectedRoute>
				),
			},
			{
				path: "/dashboard",
				exact: false,
				element: (
					<ProtectedRoute>
						<Dashboard title={'Dashboard'} auth={true} headless={true}/>
					</ProtectedRoute>
				),
			},
			// {
			// 	path: "/kepegawaian/sakit",
			// 	exact: false,
			// 	element: (
			// 		<ProtectedRoute>
			// 			<SakitPage title={'HRDetail'} auth={true}/>
			// 		</ProtectedRoute>
			// 	),
			// },
			// {
			// 	path: "/kepegawaian/cuti",
			// 	exact: false,
			// 	element: (
			// 		<ProtectedRoute>
			// 			<CutiPage title={'HRDetail'} auth={true}/>
			// 		</ProtectedRoute>
			// 	),
			// },
			// {
			// 	path: "/kepegawaian/offday",
			// 	exact: false,
			// 	element: (
			// 		<ProtectedRoute>
			// 			<OffdayPage title={'HRDetail'} auth={true}/>
			// 		</ProtectedRoute>
			// 	),
			// },
        ]
    }
]


const routes = process.env.NODE_ENV === "development" ? createBrowserRouter(routeList) : createHashRouter(routeList);

export default routes;