import '../../App.css';
import React,{useEffect, useState, useRef, useCallback} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import DatePicker from 'react-date-picker';
import { format, differenceInDays, } from "date-fns";
import "../../../node_modules/react-date-picker/dist/DatePicker.css";
import 'react-calendar/dist/Calendar.css';
import { Collapse, Modal } from 'reactstrap';
import Webcam from 'react-webcam';
import toast,{Toaster} from 'react-hot-toast'

import defaultPic from '../../Assets/Images/default_pic.png';
import addImagePic from '../../Assets/Images/add_image_gallery.png';
import backIcon from '../../Assets/Icons/back.png';
import starIcon from '../../Assets/Icons/star-p.png';
import folderIcon from '../../Assets/Icons/folder-p.png';
import walletIcon from '../../Assets/Icons/wallet-p.png';
import plusIcon from '../../Assets/Icons/plus-w.png';
import minusIcon from '../../Assets/Icons/minus-w.png';
import capturePic from '../../Assets/Images/capture.png';
import flipPic from '../../Assets/Images/flip.png';
import { getOutletId, getToken, getUser, removeSession } from '../../Helpers/auth-helper';
import { postData, postImage } from '../../Helpers/api-helper';
import Loader from '../../Components/Loader';
// import { checkCamera } from '../../Components/Permissions';
import { ExpandMore,ExpandLess } from '@mui/icons-material';


function DetailStok(){
    
    const fileInputRef=useRef();
    const webcamRef = useRef(null);
	const navigate = useNavigate();
    const {state} = useLocation()
    const {item,transaction_type,outlet_transaction_id,outlet_visit_id} = state

    const [loading, setLoading] = useState(false);
    const [timeOut, setTimeOut] = useState(process.env.REACT_APP_TIMEOUT_IMG/1000);
    const [activeButton, setActiveButton] = useState('GENERAL');
  
    const [pcs_in_rack, set_pcs_in_rack] = useState(item?.pcs_in_rack ?? 0);
    const [box_in_rack, set_box_in_rack] = useState(item?.box_in_rack ?? 0);
    const [carton_in_rack, set_carton_in_rack] = useState(item?.carton_in_rack ?? 0);
    const [col_in_rack, set_col_in_rack] = useState(true);
  
    const [pcs_in_outlet, set_pcs_in_outlet] = useState(item?.pcs_in_outlet ?? 0);
    const [box_in_outlet, set_box_in_outlet] = useState(item?.box_in_outlet ?? 0);
    const [carton_in_outlet, set_carton_in_outlet] = useState(item?.carton_in_outlet ?? 0);
    const [col_in_outlet, set_col_in_outlet] = useState(false);
  
    const [pcs_in_warehouse, set_pcs_in_warehouse] = useState(item?.pcs_in_warehouse ?? 0);
    const [box_in_warehouse, set_box_in_warehouse] = useState(item?.box_in_warehouse ?? 0);
    const [carton_in_warehouse, set_carton_in_warehouse] = useState(item?.carton_in_warehouse ?? 0);
    const [col_in_warehouse, set_col_in_warehouse] = useState(false);
  
    const [pcs_incoming, set_pcs_incoming] = useState(item?.pcs_incoming ?? 0);
    const [box_incoming, set_box_incoming] = useState(item?.box_incoming ?? 0);
    const [carton_incoming, set_carton_incoming] = useState(item?.carton_incoming ?? 0);
    const [col_incoming, set_col_incoming] = useState(false);
  
    const [pcs_expired, set_pcs_expired] = useState(item?.pcs_expired ?? 0);
    const [box_expired, set_box_expired] = useState(item?.box_expired ?? 0);
    const [carton_expired, set_carton_expired] = useState(item?.carton_expired ?? 0);
    const [expired_date, set_expired_date] = useState(item?.expired_date ?? "");
    const [expired_notes, set_expired_notes] = useState(item?.expired_notes ?? 0);
    const [expired_image, set_expired_image] = useState(item?.expired_image ?? 0);
    const [col_expired, set_col_expired] = useState(false);
  
    const [pcs_damaged, set_pcs_damaged] = useState(item?.pcs_damaged ?? 0);
    const [box_damaged, set_box_damaged] = useState(item?.box_damaged ?? 0);
    const [carton_damaged, set_carton_damaged] = useState(item?.carton_damaged ?? 0);
    const [damaged_date, set_damaged_date] = useState(item?.damaged_date ?? "");
    const [damaged_notes, set_damaged_notes] = useState(item?.damaged_notes ?? null);
    const [damaged_image, set_damaged_image] = useState(item?.damaged_image ?? null);
    const [col_damaged, set_col_damaged] = useState(false);
  
    const [oos_category_id, set_oos_category_id] = useState(item?.oos_category_id ?? null);
    const [oos_category_descr, set_oos_category_descr] = useState(item?.oos_category_descr ?? null);
    const [oos_image, set_oos_image] = useState(item?.oos_image ?? null);
  
    const [oos_categories, set_oos_categories] = useState();
    const [selected_oos_category, set_selected_oos_category] = useState([item?.oos_category_id] ?? []);
    const [modalVisible, setModalVisible] = useState(false);
    const [isPermitted, setIsPermitted] = useState(true);
    const [imageURI, setImageURI] = useState(item?.damaged_image ?? null);
    const [imageName, setImageName] = useState('');
    const [imageFile, setImageFile] = useState('');
    const [modalCamera, setModalCamera] = useState(false);
    const [picture, setPicture] = useState('');
    const [cameraPosition, switchCamera] = useState(true);
    const videoConstraints = {
      width: 640,
      height: 480,
        facingMode: { exact: cameraPosition ? 'environment' : 'user' },
    };
  
    const [damaged_date_open, set_damaged_date_open] = useState(false);
    const [expired_date_open, set_expired_date_open] = useState(false);
  
    const on_selected_oos_category_change = (selectedItems) => {
      console.log('Selected OOS', selectedItems.toString());
      set_selected_oos_category(selectedItems);
  
      for (let i = 0; i < oos_categories.length; i++) {
        if (oos_categories[i].id === selectedItems.toString()) {
          set_oos_category_id(oos_categories[i].id);
          set_oos_category_descr(oos_categories[i].name);
          break;
        }
      }
    };
  
    const generate_oos_category = () => {
      const tmp_oos_categories = [];
      tmp_oos_categories.push({name: '---', id: 'INS'});
      tmp_oos_categories.push({name: 'End Of Life', id: 'EOL'});
      tmp_oos_categories.push({name: 'Sold Out', id: 'SOT'});
      tmp_oos_categories.push({name: 'Death Stock', id: 'DST'});
      console.log('OOS', tmp_oos_categories);
      return tmp_oos_categories;
    } 
  
    const refreshView = () => {
      global.activeScreen = 'StockDetailScreen';
      console.log('Opening StockDetailScreen');
      set_oos_categories(generate_oos_category());
      global.picture = undefined;
      global.imageURI = '';
      global.imageName = '';
    }

    useEffect(() => {
        refreshView()
    },[])

    // const startCamera=async()=>{

    //   checkCamera().then(async(res) => {
    //     console.log(res.state,"camera_permit")
    //     if(res.state == 'denied'){
    //       removeSession()
    //       navigate('/login')
    //       window.location.reload();
    //     }else if(res.state == 'prompt'){
    //       const permissions = navigator.mediaDevices.getUserMedia({video: true})
    //       permissions.then((stream) => {
    //           console.log(stream)
    //           return true
    //       })
    //       .catch((err) => {
    //           removeSession()
    //           navigate('/login')
    //           window.location.reload();
    //       });
    //     }
    //   }
    //   ).catch((e) => 
    //     console.log(e,"camera_permit")
    //   )
    // }

    const onChangeQty=(name,type,value)=>{
        if(value >= 0){
            if(type == 'in_rack'){
                if(name == 'pcs'){
                    set_pcs_in_rack(value)
                }else if(name == 'box'){
                    set_box_in_rack(value)
                }else if(name == 'carton'){
                    set_carton_in_rack(value)
                }
            }else if(type == 'in_outlet'){
                if(name == 'pcs'){
                    set_pcs_in_outlet(value)
                }else if(name == 'box'){
                    set_box_in_outlet(value)
                }else if(name == 'carton'){
                    set_carton_in_outlet(value)
                }
            }else if(type == 'in_warehouse'){
                if(name == 'pcs'){
                    set_pcs_in_warehouse(value)
                }else if(name == 'box'){
                    set_box_in_warehouse(value)
                }else if(name == 'carton'){
                    set_carton_in_warehouse(value)
                }
            }else if(type == 'incoming'){
                if(name == 'pcs'){
                    set_pcs_incoming(value)
                }else if(name == 'box'){
                    set_box_incoming(value)
                }else if(name == 'carton'){
                    set_carton_incoming(value)
                }
            }else if(type == 'expired'){
                if(name == 'pcs'){
                    set_pcs_expired(value)
                }else if(name == 'box'){
                    set_box_expired(value)
                }else if(name == 'carton'){
                    set_carton_expired(value)
                }
            }else if(type == 'damaged'){
                if(name == 'pcs'){
                    set_pcs_damaged(value)
                }else if(name == 'box'){
                    set_box_damaged(value)
                }else if(name == 'carton'){
                    set_carton_damaged(value)
                }
            }
        }
    }

    const setDate=(name,date)=>{
        if(name == 'expired_date'){
            set_expired_date(date)
        }
        if(name == 'damaged_date'){
            set_damaged_date(date)
        }
    }

    const takeAPic = useCallback(() => {
      const imageSrc = webcamRef.current.getScreenshot();
      var imageName = new Date().getTime()+".jpg";
      console.log(imageName)
      setImageURI(imageSrc)
      
      setImageName(format(new Date(), "yyyyMMdd")+imageName)
      setModalCamera(false)
      setModalVisible(false)
    }, [webcamRef, setImageURI]);

    const sendImage = async () => {
      // setTimeOut(global.imageTimeOut/1000)
      // setLoading(true)
        if (imageURI != '' && imageURI != null) {
            let response_img = await fetch(imageURI);
            let data = await response_img.blob();
            let metadata = {
                type: 'image/jpeg'
            };
            let new_file = new File([data], imageName, metadata);
            const response = await postImage(new_file,imageName)
            setLoading(false)
        
            if (response) {
                if (response?.data?.success == 1) {
                    var new_pic = process.env.REACT_APP_API_URL + '/' + process.env.REACT_APP_API_FOLDER + '/' + process.env.REACT_APP_BINARY_FOLDER + '/' + 
                    imageName.substring(0,4) + '/' + imageName.substring(4,6) + '/' 
                    + imageName.substring(6,8) + '/' + imageName;
                    setPicture(new_pic)
                    return new_pic
                } else {
                var resultMessage = response?.data?.message?.toString();
                console.log('resultMessage', response);
                    if (resultMessage !== "File sudah ada di server") {
                        toast.error(resultMessage);
                        return false
                    } else {
                        var new_pic = process.env.REACT_APP_API_URL + '/' + process.env.REACT_APP_API_FOLDER + '/' + process.env.REACT_APP_BINARY_FOLDER + '/' + 
                        imageName.substring(0,4) + '/' + imageName.substring(4,6) + '/' 
                        + imageName.substring(6,8) + '/' + imageName;
                        setPicture(new_pic)
                        return new_pic
                    }
                }
            } else {
                return false
            }
        }else{
            return ''
        }
    }

    const handleSubmitButton = async () => {
        setLoading(true)
    //   if (imageURI != '' && imageURI != null) {
        const imageResponse = await sendImage()
  
        if (imageResponse === false) {
          return false
        }
    //   }
      var access_token = getToken()
      var data_user = getUser()
      var user_id = data_user?.user_id ? data_user?.user_id : data_user?.id
      let dataOptions = {access_token: access_token, product_id: item?.product_id, reference_id: outlet_visit_id, 
        reference_type: "OUTVIS", transaction_type: transaction_type, 
        outlet_id: item?.outlet_id, user_id:user_id,
        pcs_in_rack, box_in_rack, carton_in_rack, pcs_in_outlet, box_in_outlet, carton_in_outlet, 
        pcs_in_warehouse, box_in_warehouse, carton_in_warehouse, pcs_incoming, box_incoming, carton_incoming, 
        pcs_expired, box_expired, carton_expired, expired_date : expired_date ? expired_date : null, expired_notes, expired_image, 
        pcs_damaged, box_damaged, carton_damaged, damaged_date : damaged_date ? damaged_date : null, damaged_notes, 
        damaged_image: imageResponse ?? item?.damaged_image, 
        oos_category_id, oos_category_descr, oos_image,
        created_by: user_id 
      };
  
      // setTimeOut(global.timeOut/1000)
      const response = await postData(process.env.REACT_APP_BACKEND_URL+'/insert_product_stock', dataOptions)
  
      if (response) {
        setLoading(false)
        if (response?.data?.success == 1) {
            toast.success('Data Stok sudah tersimpan');
            navigate(-1)
        } else {
          var resultMessage = response?.data?.message?.toString();
          console.log('resultMessage', resultMessage);
          toast.error(resultMessage);
        }
      }else{
        setLoading(false)
      }
    };

    const numberFormatter = new Intl.NumberFormat('id-ID', {
        style: 'decimal',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    });

    return(
        <div className='page-background'>
            <Loader loading={loading} timeOut={60}/>
            <div className='detail-page'>
                <div className='cards' style={{backgroundColor:"#fafafa",width:"100%",borderRadius:10}}>
                    <div className='d-flex'>
                        <img className='card-img-product' src={defaultPic} width={90} height={90}/>
                        <div className='card-text'>
                            <div className='card-title'>
                                {item?.product}
                            </div>
                            <div className='card-desc' style={{fontWeight:600}}>
                                {item?.storage_type}
                            </div>
                            <div className='card-item' style={{height:20}}>
                                <img src={walletIcon} width={18} height={18}/>
                                <p style={{fontWeight:700}}>
                                {'Rp' + numberFormatter.format(item?.price_in_a_unit)}
                                </p>
                            </div>
                            <div className='card-icons-2 d-flex' style={{marginRight:10}}>
                                <div style={{marginRight:'auto'}}>

                                </div>
                                <div className='card-item'>
                                    <img src={folderIcon} width={18} height={18}/>
                                    <p style={{fontWeight:700}}>{item?.brand}</p>
                                </div>
                                <div className='card-item'>
                                    <img src={starIcon} width={18} height={18}/>
                                    <p style={{fontWeight:700}}>{item?.category}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='stock-lists'>
                        <div className='stock-details'>
                            <div className='stock-header' onClick={() => set_col_in_rack(!col_in_rack)}>
                                <h4 style={{fontWeight:700}}>Stok di Rak {
                                    col_in_rack == true ? <ExpandLess style={{color:"black"}}/> : <ExpandMore style={{color:"black"}}/>
                                }</h4>
                                <p>Isi jumlah stok yang terpajang dirak</p>
                            </div>
                            <Collapse isOpen={col_in_rack}>
                                <div className='SO-body'>
                                    <div className='SO-item d-flex'>
                                        <p>Pcs :</p>
                                        <div className='SO-qty'>
                                            <button className='SO-qty-minus-button' onClick={()=>onChangeQty('pcs','in_rack',pcs_in_rack-1)}><img src={minusIcon} width={25} height={25}/></button>
                                            <input type='number' className='SO-qty-input' onChange={(e) => onChangeQty('pcs','in_rack',e.target.value)} value={pcs_in_rack}/>
                                            <button className='SO-qty-plus-button' onClick={()=>onChangeQty('pcs','in_rack',pcs_in_rack+1)}><img src={plusIcon} width={25} height={25}/></button>
                                        </div>
                                    </div>
                                    <div className='SO-item d-flex'>
                                        <p>Box :</p>
                                        <div className='SO-qty'>
                                            <button className='SO-qty-minus-button' onClick={()=>onChangeQty('box','in_rack',box_in_rack-1)}><img src={minusIcon} width={25} height={25}/></button>
                                            <input type='number' className='SO-qty-input' onChange={(e) => onChangeQty('box','in_rack',e.target.value)} value={box_in_rack}/>
                                            <button className='SO-qty-plus-button' onClick={()=>onChangeQty('box','in_rack',box_in_rack+1)}><img src={plusIcon} width={25} height={25}/></button>
                                        </div>
                                    </div>
                                    <div className='SO-item d-flex'>
                                        <p>Carton :</p>
                                        <div className='SO-qty'>
                                            <button className='SO-qty-minus-button' onClick={()=>onChangeQty('carton','in_rack',carton_in_rack-1)}><img src={minusIcon} width={25} height={25}/></button>
                                            <input type='number' className='SO-qty-input' onChange={(e) => onChangeQty('carton','in_rack',e.target.value)} value={carton_in_rack}/>
                                            <button className='SO-qty-plus-button' onClick={()=>onChangeQty('carton','in_rack',carton_in_rack+1)}><img src={plusIcon} width={25} height={25}/></button>
                                        </div>
                                    </div>
                                </div>
                            </Collapse>
                        </div>
                        <div className='stock-details'>
                            <div className='stock-header' onClick={() => set_col_in_outlet(!col_in_outlet)}>
                                <h4 style={{fontWeight:700}}>Stok Cadangan {
                                    col_in_outlet == true ? <ExpandLess style={{color:"black"}}/> : <ExpandMore style={{color:"black"}}/>
                                }</h4>
                                <p>Isi jumlah stok yang terpajang di outlet</p>
                            </div>
                            <Collapse isOpen={col_in_outlet}>
                                <div className='SO-body'>
                                    <div className='SO-item d-flex'>
                                        <p>Pcs :</p>
                                        <div className='SO-qty'>
                                            <button className='SO-qty-minus-button' onClick={()=>onChangeQty('pcs','in_outlet',pcs_in_outlet-1)}><img src={minusIcon} width={25} height={25}/></button>
                                            <input type='number' className='SO-qty-input' onChange={(e) => onChangeQty('pcs','in_outlet',e.target.value)} value={pcs_in_outlet}/>
                                            <button className='SO-qty-plus-button' onClick={()=>onChangeQty('pcs','in_outlet',pcs_in_outlet+1)}><img src={plusIcon} width={25} height={25}/></button>
                                        </div>
                                    </div>
                                    <div className='SO-item d-flex'>
                                        <p>Box :</p>
                                        <div className='SO-qty'>
                                            <button className='SO-qty-minus-button' onClick={()=>onChangeQty('box','in_outlet',box_in_outlet-1)}><img src={minusIcon} width={25} height={25}/></button>
                                            <input type='number' className='SO-qty-input' onChange={(e) => onChangeQty('box','in_outlet',e.target.value)} value={box_in_outlet}/>
                                            <button className='SO-qty-plus-button' onClick={()=>onChangeQty('box','in_outlet',box_in_outlet+1)}><img src={plusIcon} width={25} height={25}/></button>
                                        </div>
                                    </div>
                                    <div className='SO-item d-flex'>
                                        <p>Carton :</p>
                                        <div className='SO-qty'>
                                            <button className='SO-qty-minus-button' onClick={()=>onChangeQty('carton','in_outlet',carton_in_outlet-1)}><img src={minusIcon} width={25} height={25}/></button>
                                            <input type='number' className='SO-qty-input' onChange={(e) => onChangeQty('carton','in_outlet',e.target.value)} value={carton_in_outlet}/>
                                            <button className='SO-qty-plus-button' onClick={()=>onChangeQty('carton','in_outlet',carton_in_outlet+1)}><img src={plusIcon} width={25} height={25}/></button>
                                        </div>
                                    </div>
                                </div>
                            </Collapse>
                        </div>
                        <div className='stock-details'>
                            <div className='stock-header' onClick={() => set_col_in_warehouse(!col_in_warehouse)}>
                                <h4 style={{fontWeight:700}}>Stok di Gudang {
                                    col_in_warehouse == true ? <ExpandLess style={{color:"black"}}/> : <ExpandMore style={{color:"black"}}/>
                                }</h4>
                                <p>Isi jumlah stok yang ada di gudang</p>
                            </div>
                            <Collapse isOpen={col_in_warehouse}>
                                <div className='SO-body'>
                                    <div className='SO-item d-flex'>
                                        <p>Pcs :</p>
                                        <div className='SO-qty'>
                                            <button className='SO-qty-minus-button' onClick={()=>onChangeQty('pcs','in_warehouse',pcs_in_warehouse-1)}><img src={minusIcon} width={25} height={25}/></button>
                                            <input type='number' className='SO-qty-input' onChange={(e) => onChangeQty('pcs','in_warehouse',e.target.value)} value={pcs_in_warehouse}/>
                                            <button className='SO-qty-plus-button' onClick={()=>onChangeQty('pcs','in_warehouse',pcs_in_warehouse+1)}><img src={plusIcon} width={25} height={25}/></button>
                                        </div>
                                    </div>
                                    <div className='SO-item d-flex'>
                                        <p>Box :</p>
                                        <div className='SO-qty'>
                                            <button className='SO-qty-minus-button' onClick={()=>onChangeQty('box','in_warehouse',box_in_warehouse-1)}><img src={minusIcon} width={25} height={25}/></button>
                                            <input type='number' className='SO-qty-input' onChange={(e) => onChangeQty('box','in_warehouse',e.target.value)} value={box_in_warehouse}/>
                                            <button className='SO-qty-plus-button' onClick={()=>onChangeQty('box','in_warehouse',box_in_warehouse+1)}><img src={plusIcon} width={25} height={25}/></button>
                                        </div>
                                    </div>
                                    <div className='SO-item d-flex'>
                                        <p>Carton :</p>
                                        <div className='SO-qty'>
                                            <button className='SO-qty-minus-button' onClick={()=>onChangeQty('carton','in_warehouse',carton_in_warehouse-1)}><img src={minusIcon} width={25} height={25}/></button>
                                            <input type='number' className='SO-qty-input' onChange={(e) => onChangeQty('carton','in_warehouse',e.target.value)} value={carton_in_warehouse}/>
                                            <button className='SO-qty-plus-button' onClick={()=>onChangeQty('carton','in_warehouse',carton_in_warehouse+1)}><img src={plusIcon} width={25} height={25}/></button>
                                        </div>
                                    </div>
                                </div>
                            </Collapse>
                        </div>
                        <div className='stock-details'>
                            <div className='stock-header' onClick={() => set_col_incoming(!col_incoming)}>
                                <h4 style={{fontWeight:700}}>Stok Masuk {
                                    col_incoming == true ? <ExpandLess style={{color:"black"}}/> : <ExpandMore style={{color:"black"}}/>
                                }</h4>
                                <p>Isi jumlah stok yang Masuk</p>
                            </div>
                            <Collapse isOpen={col_incoming}>
                                <div className='SO-body'>
                                    <div className='SO-item d-flex'>
                                        <p>Pcs :</p>
                                        <div className='SO-qty'>
                                            <button className='SO-qty-minus-button' onClick={()=>onChangeQty('pcs','incoming',pcs_incoming-1)}><img src={minusIcon} width={25} height={25}/></button>
                                            <input type='number' className='SO-qty-input' onChange={(e) => onChangeQty('pcs','incoming',e.target.value)} value={pcs_incoming}/>
                                            <button className='SO-qty-plus-button' onClick={()=>onChangeQty('pcs','incoming',pcs_incoming+1)}><img src={plusIcon} width={25} height={25}/></button>
                                        </div>
                                    </div>
                                    <div className='SO-item d-flex'>
                                        <p>Box :</p>
                                        <div className='SO-qty'>
                                            <button className='SO-qty-minus-button' onClick={()=>onChangeQty('box','incoming',box_incoming-1)}><img src={minusIcon} width={25} height={25}/></button>
                                            <input type='number' className='SO-qty-input' onChange={(e) => onChangeQty('box','incoming',e.target.value)} value={box_incoming}/>
                                            <button className='SO-qty-plus-button' onClick={()=>onChangeQty('box','incoming',box_incoming+1)}><img src={plusIcon} width={25} height={25}/></button>
                                        </div>
                                    </div>
                                    <div className='SO-item d-flex'>
                                        <p>Carton :</p>
                                        <div className='SO-qty'>
                                            <button className='SO-qty-minus-button' onClick={()=>onChangeQty('carton','incoming',carton_incoming-1)}><img src={minusIcon} width={25} height={25}/></button>
                                            <input type='number' className='SO-qty-input' onChange={(e) => onChangeQty('carton','incoming',e.target.value)} value={carton_incoming}/>
                                            <button className='SO-qty-plus-button' onClick={()=>onChangeQty('carton','incoming',carton_incoming+1)}><img src={plusIcon} width={25} height={25}/></button>
                                        </div>
                                    </div>
                                </div>
                            </Collapse>
                        </div>
                        <div className='stock-details'>
                            <div className='stock-header' onClick={() => set_col_expired(!col_expired)}>
                                <h4 style={{fontWeight:700}}>Stok Kadaluwarsa {
                                    col_expired == true ? <ExpandLess style={{color:"black"}}/> : <ExpandMore style={{color:"black"}}/>
                                }</h4>
                                <p>Isi jumlah stok yang kadaluwarsa</p>
                            </div>
                            <Collapse isOpen={col_expired}>
                                <div className='SO-body'>
                                    <div className='SO-item d-flex'>
                                        <p>Pcs :</p>
                                        <div className='SO-qty'>
                                            <button className='SO-qty-minus-button' onClick={()=>onChangeQty('pcs','expired',pcs_expired-1)}><img src={minusIcon} width={25} height={25}/></button>
                                            <input type='number' className='SO-qty-input' onChange={(e) => onChangeQty('pcs','expired',e.target.value)} value={pcs_expired}/>
                                            <button className='SO-qty-plus-button' onClick={()=>onChangeQty('pcs','expired',pcs_expired+1)}><img src={plusIcon} width={25} height={25}/></button>
                                        </div>
                                    </div>
                                    <div className='SO-item d-flex'>
                                        <p>Box :</p>
                                        <div className='SO-qty'>
                                            <button className='SO-qty-minus-button' onClick={()=>onChangeQty('box','expired',box_expired-1)}><img src={minusIcon} width={25} height={25}/></button>
                                            <input type='number' className='SO-qty-input' onChange={(e) => onChangeQty('box','expired',e.target.value)} value={box_expired}/>
                                            <button className='SO-qty-plus-button' onClick={()=>onChangeQty('box','expired',box_expired+1)}><img src={plusIcon} width={25} height={25}/></button>
                                        </div>
                                    </div>
                                    <div className='SO-item d-flex'>
                                        <p>Carton :</p>
                                        <div className='SO-qty'>
                                            <button className='SO-qty-minus-button' onClick={()=>onChangeQty('carton','expired',carton_expired-1)}><img src={minusIcon} width={25} height={25}/></button>
                                            <input type='number' className='SO-qty-input' onChange={(e) => onChangeQty('carton','expired',e.target.value)} value={carton_expired}/>
                                            <button className='SO-qty-plus-button' onClick={()=>onChangeQty('carton','expired',carton_expired+1)}><img src={plusIcon} width={25} height={25}/></button>
                                        </div>
                                    </div>
                                </div>
                            </Collapse>
                            <div className='stock-footer'>
                                <button className='stock-button-tanggal' onClick={() => set_expired_date_open(true)}>Tanggal Kadaluwarsa</button>
                                <DatePicker 
                                    className="input-date-1" 
                                    name="fromDate" 
                                    dateFormat="yyyy-MM-dd" 
                                    selected={expired_date || new Date()} 
                                    onChange={(date) => setDate('expired_date',date)}
                                    isOpen={expired_date_open}
                                    minDate={new Date()}
                                    onCalendarClose={() => set_expired_date_open(false)}
                                />
                                <label>{expired_date && format(expired_date, "dd MMMM yyyy")}</label>
                            </div>
                        </div>
                        <div className='stock-details'>
                            <div className='stock-header' onClick={() => set_col_damaged(!col_damaged)}>
                                <h4 style={{fontWeight:700}}>Stok Rusak {
                                    col_damaged == true ? <ExpandLess style={{color:"black"}}/> : <ExpandMore style={{color:"black"}}/>
                                }</h4>
                                <p>Isi jumlah stok yang rusak</p>
                            </div>
                            <Collapse isOpen={col_damaged}>
                                <div className='SO-body'>
                                    <div className='SO-item d-flex'>
                                        <p>Pcs :</p>
                                        <div className='SO-qty'>
                                            <button className='SO-qty-minus-button' onClick={()=>onChangeQty('pcs','damaged',pcs_damaged-1)}><img src={minusIcon} width={25} height={25}/></button>
                                            <input type='number' className='SO-qty-input' onChange={(e) => onChangeQty('pcs','damaged',e.target.value)} value={pcs_damaged}/>
                                            <button className='SO-qty-plus-button' onClick={()=>onChangeQty('pcs','damaged',pcs_damaged+1)}><img src={plusIcon} width={25} height={25}/></button>
                                        </div>
                                    </div>
                                    <div className='SO-item d-flex'>
                                        <p>Box :</p>
                                        <div className='SO-qty'>
                                            <button className='SO-qty-minus-button' onClick={()=>onChangeQty('box','damaged',box_damaged-1)}><img src={minusIcon} width={25} height={25}/></button>
                                            <input type='number' className='SO-qty-input' onChange={(e) => onChangeQty('box','damaged',e.target.value)} value={box_damaged}/>
                                            <button className='SO-qty-plus-button' onClick={()=>onChangeQty('box','damaged',box_damaged+1)}><img src={plusIcon} width={25} height={25}/></button>
                                        </div>
                                    </div>
                                    <div className='SO-item d-flex'>
                                        <p>Carton :</p>
                                        <div className='SO-qty'>
                                            <button className='SO-qty-minus-button' onClick={()=>onChangeQty('carton','damaged',carton_damaged-1)}><img src={minusIcon} width={25} height={25}/></button>
                                            <input type='number' className='SO-qty-input' onChange={(e) => onChangeQty('carton','damaged',e.target.value)} value={carton_damaged}/>
                                            <button className='SO-qty-plus-button' onClick={()=>onChangeQty('carton','damaged',carton_damaged+1)}><img src={plusIcon} width={25} height={25}/></button>
                                        </div>
                                    </div>
                                </div>
                            </Collapse>
                            <div className='stock-footer'>
                                <button className='stock-button-tanggal' onClick={() => set_damaged_date_open(true)}>Tanggal Rusak</button>
                                <DatePicker 
                                    className="input-date-1" 
                                    name="fromDate" 
                                    dateFormat="yyyy-MM-dd" 
                                    selected={damaged_date || new Date()} 
                                    onChange={(date) => setDate('damaged_date',date)}
                                    isOpen={damaged_date_open}
                                    minDate={new Date()}
                                    onCalendarClose={() => set_damaged_date_open(false)}
                                />
                                <label>{damaged_date && format(damaged_date, "dd MMMM yyyy")}</label>
                            </div>
                        </div>
                        <div className='add-image'>
                            <img onClick={() => {
                                setModalVisible(true)
                                // startCamera()
                                }} src={imageURI ? imageURI : addImagePic} className='add-image-button' width={'100%'} height={150}/>
                        </div>
                        <div className='form-input'>
                            <label>Catatan :</label>
                            <input className='form-input-2' placeholder='Catatan Kerusakan' value={damaged_notes} onChange={(e) => set_damaged_notes(e.target.value)}></input>
                        </div>
                        <div className='form-input'>
                            <h4>Kategori OOS</h4>
                            <select className='form-select-1' value={selected_oos_category} onChange={(e) => on_selected_oos_category_change(e.target.value)}>
                                <option default>Pilih Kategori OOS</option>
                                {
                                    oos_categories && oos_categories.map((data,index) =>
                                        <option value={data.id}>{data.name}</option>
                                    )
                                }
                            </select>
                        </div>
                        <div className='d-flex'>
                            <button className='outlet-checkout-button' onClick={() => handleSubmitButton()}>SIMPAN</button>
                        </div>
                    </div>
                </div>
            </div>
            <Modal isOpen={modalVisible} onClosed={() => setModalVisible(false)} centered toggle={() => setModalVisible(false)} className='modal-gambar'>
                    <button className='btn-red' onClick={() => fileInputRef.current.click()}>FOTO DARI GALERI</button>
                    <input
                        ref={fileInputRef}
                        type="file"
                        name="imageURI"
                        onChange={(event) => {
                            console.log(event.target.files[0])
                            setImageURI(URL.createObjectURL(event.target.files[0]))
                            setImageFile(event.target.files[0])
                            setImageName(format(new Date(), "yyyyMMdd")+event.target.files[0].name)
                            setModalVisible(false)
                        }}
                        style={{display:'none'}}
                    />
                    <button className='btn-red' onClick={() => setModalCamera(true)}>FOTO DARI KAMERA</button>
                    <Modal isOpen={modalCamera} onClosed={() => setModalCamera(false)} centered toggle={() => setModalCamera(false)} fullscreen className='modal-gambar'>
                        <Webcam
                            audio={false}
                            ref={webcamRef}
                            screenshotFormat="image/jpeg"
                            videoConstraints={videoConstraints}
                            minScreenshotWidth={640}
                            minScreenshotHeight={'100%'}
                            screenshotQuality={1}
                            label="wow"
                            style={{
                            position: "absolute",
                            zindex: 888,
                            left:0,
                            right:0,
                            height: "90vh",
                            width: "100%",
                            objectFit: "cover",
                            }}
                        />
                        <div className='group-camera-buttons'>
                            <div className='camera-button' onClick={() => setModalCamera(false)}>
                                <p style={{color:"black"}}>BATAL</p>
                            </div>
                            <div className='camera-button'>
                                <img onClick={takeAPic} src={capturePic} width={70} height={70}/>
                            </div>
                            <div className='camera-button'>
                                <img onClick={() => switchCamera(!cameraPosition)} src={flipPic} width={110} height={60}/>
                            </div>
                        </div>
                    </Modal>
            </Modal>
        </div>
    )
}

export default DetailStok