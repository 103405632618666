import '../../App.css';
import React,{useEffect, useState} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import backIcon from '../../Assets/Icons/back.png';

import defaultPic from '../../Assets/Images/default_pic.png';
import starIcon from '../../Assets/Icons/star-p.png';
import folderIcon from '../../Assets/Icons/folder-p.png';
import walletIcon from '../../Assets/Icons/wallet-p.png';
import { getOutletId, getToken, getUser } from '../../Helpers/auth-helper';
import toast,{ Toaster } from 'react-hot-toast';
import { getData, postData } from '../../Helpers/api-helper';
import Loader from '../../Components/Loader';
import BarcodeScanner from '../../Components/BarcodeScanner';
import { Modal, ModalBody } from 'reactstrap';

function DetailPemesananDanPenjualan(){
    const data_user = getUser()
	const navigate = useNavigate();
    const {state} = useLocation();
    const {item,transaction_type,outlet_transaction_id,outlet_visit_id} = state;
    const [loading, setLoading] = useState(false);
    const [timeOut, setTimeOut] = useState(process.env.REACT_APP_TIMEOUT_IMG/1000);
    const [pcsCount, setPcsCount] = useState(item?.number_of_piece ?? 0);
    const [boxCount, setBoxCount] = useState(item?.number_of_box ?? 0);
    const [cartonCount, setCartonCount] = useState(item?.number_of_carton ?? 0);
    const [modalBarcode, setModalBarcode] = useState(false)
    const [modalView, setModalView] = useState(false)

    const [scanning, set_scanning] = useState(false)

    const [selected_type,set_selected_type] = useState('')
    const [serial_numbers,set_serial_numbers] = useState([])
    const [stocks,set_stocks] = useState([])
    const [list_deleted,set_list_deleted] = useState([])

    const onChangeQty=(name,value)=>{
        if(value >= 0){
            if(name == 'pcs'){
                setPcsCount(value)
            }else if(name == 'box'){
                setBoxCount(value)
            }else if(name == 'carton'){
                setCartonCount(value)
            }
        }
    }
    
    useEffect(() => {
        console.log(item)
        if(item?.order_type == 'serial_number'){
            // getSerialNumbersBySKU(item.sku_code)
            if(item?.entry_date){
                setLoading(true)
                getSerialNumbersOfTransaction(item?.reference_id,item?.product_id,item?.outlet_id,item?.user_id,item?.entry_date)
            }
        }
    },[])

    const getSerialNumbersBySKU=async(sku_code)=>{
        const response = await getData(process.env.REACT_APP_BACKEND_URL+'/get_serial_numbers_by_sku?sku_code='+sku_code+"&status_stock="+0+"&search=saos_kfc")
        if(response){
            if(response?.data){
                setLoading(false)
                set_stocks(response?.data)
            }
        }
    }

    const checkSN=async(serial_number,sku_code,outlet_id,reference_id)=>{
        var user_id = data_user?.user_id ? data_user?.user_id : data_user?.id
        const response = await getData(process.env.REACT_APP_BACKEND_URL+'/scan_sn?serial_number='+serial_number+"&sku_code="+sku_code+"&user_id="+user_id+"&outlet_id="+outlet_id+"&reference_id="+reference_id)
        if(response){
            return response?.data
        }
    }

    const getSerialNumbersOfTransaction=async(reference_id,product_id,outlet_id,user_id,entry_date)=>{
        const response = await getData(process.env.REACT_APP_BACKEND_URL+'/get_serial_numbers_of_transaction?reference_id='+reference_id+"&product_id="+product_id+"&outlet_id="+outlet_id+"&user_id="+user_id+"&entry_date="+entry_date)
        if(response){
            if(response?.data){
                set_serial_numbers(response?.data)
                setLoading(false)
            }
        }
    }

    const handleSubmitButton = async () => {
        const access_token = getToken()
        console.log(outlet_visit_id,"outlet_visit_id")
        console.log(item?.outlet_transaction_id,"outlet_transaction_id")
        console.log(item?.outlet_id,"outlet_id")
        var user_id = data_user?.user_id ? data_user?.user_id : data_user?.id
        let dataOptions = {access_token: access_token, product_id: item?.product_id, 
        reference_id: outlet_visit_id === undefined || outlet_visit_id == null ? 
        item?.outlet_transaction_id : outlet_visit_id, 
        user_id: user_id,
        reference_type: outlet_visit_id === undefined || outlet_visit_id === null ? 'NOTVIS' : 'OUTVIS', 
        transaction_type: transaction_type, outlet_id: item?.outlet_id, 
        number_of_piece: pcsCount, number_of_box: boxCount, number_of_carton: cartonCount, serial_numbers:serial_numbers,sku_code:item?.sku_code,list_deleted:list_deleted,
        created_by: user_id};
    
        setLoading(true)
        const response = await postData(process.env.REACT_APP_BACKEND_URL+'/insert_product_transaction', dataOptions)
    
        if (response) {
            setLoading(false)
            if (response?.data?.success == 1) {
                if (transaction_type == 'SALES') {
                toast.success('Data penjualan sudah tersimpan');
                } else {
                toast.success('Data pemesanan sudah tersimpan');
                }
                navigate(-1)
            } else {
                var resultMessage = response?.data?.message?.toString();
                console.log('resultMessage', resultMessage);
                toast.error(resultMessage);
            }
        }else{
            setLoading(false)
        }
    };

    const numberFormatter = new Intl.NumberFormat('id-ID', {
        style: 'decimal',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    });

    const startCount=(type,new_count)=>{
        if(type === 'pcs'){
            setPcsCount(new_count == 'add' ? pcsCount+1 : pcsCount-1)
        }else if(type == 'box'){
            setBoxCount(new_count == 'add' ? boxCount+1 : boxCount-1)
        }else if(type == 'carton'){
            setCartonCount(new_count == 'add' ? cartonCount+1 : cartonCount-1)
        }
    }

    const onNewScanResult = async (decodedText, decodedResult) => {
        set_scanning(true)
        console.log(decodedText, decodedResult)
        if(!scanning){
            setModalBarcode(false)
            const check_sn = await checkSN(decodedText,item?.sku_code,item?.outlet_id,outlet_visit_id === undefined || outlet_visit_id == null ? item?.outlet_transaction_id : outlet_visit_id)
            const indexS = serial_numbers.findIndex(item => item.serial_number == decodedText && item.type_row == selected_type)
            set_scanning(false)
            if(indexS == -1){
                if(check_sn){
                    let new_array = {
                        type_row:selected_type,
                        serial_number:decodedText
                    }
                    set_serial_numbers(oldArray => [...oldArray,new_array]);
                    startCount(selected_type,'add')
                }else{
                    toast.error("Serial Number tidak tersedia pada produk ini");
                }
            }else{
                toast.error("Serial Number sudah terpilih");
            }
        }
    };

    const startScan=(type)=>{
        set_selected_type(type)
        setModalBarcode(true)
    }

    const viewList=(type)=>{
        set_selected_type(type)
        setModalView(true)
    }

    const deleteSerialNumber=(serial_number)=>{
        if(item?.entry_date){
            const indexS = stocks.findIndex(item => item.serial_number == serial_number)
            const newStock = [...stocks];
            newStock[indexS].status_stock = 1;
            set_stocks(newStock)
            var list = {serial_number:serial_number}
            list_deleted.push(list)
        }
        var new_array = serial_numbers.filter(item => item.serial_number !== serial_number)
        set_serial_numbers(new_array)
        startCount(selected_type,'remove')
        if(new_array.length == 0){
            setModalView(false)
        }
    }

    return(
        <div className='page-background'>
        <Loader loading={loading} timeOut={60}/>
            <div className='detail-page'>
                <div className='cards' style={{backgroundColor:"#fafafa",width:"100%",borderRadius:10}}>
                    <div className='d-flex'>
                        <img className='card-img-product' src={defaultPic} width={90} height={90}/>
                        <div className='card-text'>
                            <div className='card-title'>
                                {item?.product}
                            </div>
                            <div className='card-desc' style={{fontWeight:600}}>
                                {item?.storage_type}
                            </div>
                            <div className='card-item' style={{height:20}}>
                                <img src={walletIcon} width={18} height={18}/>
                                <p style={{fontWeight:700}}>
                                    {'Rp' + numberFormatter.format(item?.price_in_a_unit)}
                                </p>
                            </div>
                            <div className='card-icons-2 d-flex' style={{marginRight:10}}>
                                <div style={{marginRight:'auto'}}>

                                </div>
                                <div className='card-item'>
                                    <img src={folderIcon} width={18} height={18}/>
                                    <p style={{fontWeight:700}}>{item?.brand}</p>
                                </div>
                                <div className='card-item'>
                                    <img src={starIcon} width={18} height={18}/>
                                    <p style={{fontWeight:700}}>{item?.category}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {item?.order_type === 'serial_number' && transaction_type == 'SALES' ? 
                    <div className='SO-lists'>
                        <div className='stock-details'>
                            <div className='stock-header'>
                                <p>{transaction_type == 'SALES' ? `Isi jumlah produk yang terjual` : `Isi jumlah produk yang dipesan`}</p>
                            </div>
                            <div className='SO-body'>
                                <h5 style={{marginLeft:'auto',marginRight:'auto'}}>Pcs : {pcsCount}</h5>
                                <div className='SO-item d-flex'>
                                    <button className='btn-scan-serial' onClick={()=> viewList('pcs')}>View</button>&nbsp;
                                    <button className='btn-scan-serial' onClick={()=> startScan('pcs')}>Scan</button>
                                </div>
                            </div>
                        </div>
                    </div>
                : <div className='SO-lists'>
                    <div className='stock-details'>
                        <div className='stock-header'>
                            <p>{transaction_type == 'SALES' ? `Isi jumlah produk yang terjual` : `Isi jumlah produk yang dipesan`}</p>
                        </div>
                        <div className='SO-body'>
                            <div className='SO-item d-flex'>
                                <p>Pcs :</p>
                                <div className='SO-qty'>
                                    <button className='SO-qty-minus-button' onClick={()=>onChangeQty('pcs',pcsCount-1)}>-</button>
                                    <input minLength={0} type='number' value={pcsCount} className='SO-qty-input' onChange={(e) => onChangeQty('pcs',e.target.value)}/>
                                    <button className='SO-qty-plus-button' onClick={()=>onChangeQty('pcs',pcsCount+1)}>+</button>
                                </div>
                            </div>
                            <div className='SO-item d-flex'>
                                <p>Box :</p>
                                <div className='SO-qty'>
                                    <button className='SO-qty-minus-button' onClick={()=>onChangeQty('box',boxCount-1)}>-</button>
                                    <input min={0} type='number' value={boxCount} className='SO-qty-input' onChange={(e) => onChangeQty('box',e.target.value)}/>
                                    <button className='SO-qty-plus-button' onClick={()=>onChangeQty('box',boxCount+1)}>+</button>
                                </div>
                            </div>
                            <div className='SO-item d-flex'>
                                <p>Carton :</p>
                                <div className='SO-qty'>
                                    <button className='SO-qty-minus-button' onClick={()=>onChangeQty('carton',cartonCount-1)}>-</button>
                                    <input min={0} type='number' value={cartonCount} className='SO-qty-input' onChange={(e) => onChangeQty('carton',e.target.value)}/>
                                    <button className='SO-qty-plus-button' onClick={()=>onChangeQty('carton',cartonCount+1)}>+</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                }
                <button className='outlet-checkout-button' onClick={() => handleSubmitButton()}>SIMPAN</button>
            </div>
            <Modal isOpen={modalView} size='sm' toggle={() => setModalView(false)} onClosed={() => setModalView(false)} centered>
                <ModalBody>
                    <h3 style={{textAlign:'center',marginBottom:'20px'}}><b>View {selected_type}</b></h3>
                    {
                        serial_numbers && serial_numbers.filter(row => row.type_row == selected_type).map((data) =>
                            <div className='d-flex' style={{marginTop:'10px'}}>
                                <h5 style={{marginLeft:'auto',marginRight:'auto'}}>{data.serial_number}</h5>
                                <h5 style={{marginLeft:'auto',marginRight:'auto',color:'red'}} onClick={() => deleteSerialNumber(data.serial_number)}>Hapus</h5>
                            </div>
                        )
                    }
                    <div className="btn-group-modal-footer">
                        <button className="btn-modal-footer" style={{marginRight:'auto'}} onClick={() => setModalView(false)}>Tutup</button>
                    </div>
                </ModalBody>
            </Modal>
            <Modal isOpen={modalBarcode} size='sm' toggle={() => setModalBarcode(false)} onClosed={() => setModalBarcode(false)} centered>
                <ModalBody>
                    <h3 style={{textAlign:'center'}}><b>Scan Barcode</b></h3>
                    <BarcodeScanner
                        fps={2}
                        qrbox={250}
                        disableFlip={false}
                        qrCodeSuccessCallback={(decodedText, decodedResult) => onNewScanResult(decodedText, decodedResult)}
                    />
                </ModalBody>
            </Modal>
        </div>
    )
}

export default DetailPemesananDanPenjualan