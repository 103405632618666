import { Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from "reactstrap";
import React, { useState, useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import toast from "react-hot-toast";

const Loader =(props)=>{
    const {loading, timeOut} = props;
    const loadingRef = useRef(loading);
    const [visible, setVisible] = useState(loading)
    const [tickCount, setTickCount] = useState(timeOut ?? process.env.REACT_APP_TIMEOUT_IMG/1000)

    useEffect(() => {
        const interval = setInterval(() => {
            if (tickCount > 0) {
                setTickCount(tickCount-1);
            }
            if (tickCount === 0) {
                clearInterval(interval);
                setVisible(false)
                // toast.error("Network Error")
            }
        }, 1000);
        
        return () => {
            clearInterval(interval);
        };
    },[tickCount])

    useEffect(() => {
      setVisible(loading)
      setTickCount(timeOut ?? process.env.REACT_APP_TIMEOUT_IMG/1000)
    },[loading])

    return(
        <Modal 
            isOpen={visible}
            centered={true}
            className="spinner"
            style={{width:50,margin:'auto'}}
        >
            <div className="modal-loader">
                <Spinner
                color="dark"
                size=""
                style={{margin:"auto"}}
                >
                </Spinner>
                <p className="tick-count">
                    <b>{tickCount}</b>
                </p>
            </div>
        </Modal>
    )
}

export default Loader