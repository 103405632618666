
import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
import React,{useCallback, useRef, useState} from "react";
import { useNavigate,useLocation } from 'react-router-dom';
import { format } from "date-fns";
import Webcam from 'react-webcam';

import LogoPic from '../Assets/Images/default_pic.png';
import RenderHeader from './Header';
import { getToken, getUser, removeSession } from '../Helpers/auth-helper';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import toast from 'react-hot-toast';
import axios from 'axios';
import useSWR from 'swr';
import { getData, postData, postImage } from '../Helpers/api-helper';
import Loader from './Loader';
import capturePic from '../Assets/Images/capture.png';
import flipPic from '../Assets/Images/flip.png';

const RenderSidebar =(props)=>{

    const fileInputRef=useRef();
    const webcamRef = useRef(null);
    const location = useLocation();
    const navigate = useNavigate()
    const [modalLogout, setModalLogout] = useState(false);
    const data_user = getUser();
    const [modalVisible, setModalVisible] = useState(false);
    const [modalCamera, setModalCamera] = useState(false);
    const [cameraPosition, switchCamera] = useState(true);
    const [loading, setLoading] = useState(false);

    const videoConstraints = {
        width: 640,
        height: 480,
        facingMode: { exact: cameraPosition ? 'environment' : 'user' },
    };

    const handleToggleSidebar = () => {
        props.setCollapsed(!props.collapsed);
    };

    const styles = {
        sidebarButtonActive:{
            width:"90%",backgroundColor:"#EC008C",color:"white",margin:"auto",marginTop:7,borderRadius:5,textAlign:'left',fontWeight:500
        },
        sidebarButton:{
            width:"90%",backgroundColor:"#f1dede",color:"black",margin:"auto",marginTop:7,borderRadius:5,textAlign:'left',fontWeight:500
        }
    }

    const Logout =()=>{
        localStorage.removeItem('token')
        localStorage.removeItem('data_user')
        localStorage.removeItem('user')
        localStorage.removeItem('role_for_promotor')
        localStorage.removeItem('fcmToken')
        navigate('/login')
    }

    const updateSystem=async()=>{
        const response = await getData(process.env.REACT_APP_BACKEND_URL+'/get_parameter_by_key?param_key=PWA_VERSION')
        console.log(response)
        const api_version = response ? response?.data.parameter[0].param_value : process.env.REACT_APP_VERSION
        if(api_version !== process.env.REACT_APP_VERSION){
            toast('System Updating');
            caches.keys().then(function(names) {
            for (let name of names)
                caches.delete(name);
            });
            window.location.href = "./";
            console.log("Changing Version to", api_version)
        }else{
            toast('Anda dalam versi terakhir');
        }
    }

    const openModalImage=()=>{
        setModalVisible(true)
        // startCamera()
    }

    const takeAPic = useCallback(() => {
      const imageSrc = webcamRef.current.getScreenshot();
      var imageName = new Date().getTime()+".jpg";
      setModalCamera(false)
      setModalVisible(false)
      updateImageProfile(imageSrc,format(new Date(), "yyyyMMdd")+imageName)
    }, [webcamRef]);

    const sendImage = async (imageURI,imageName) => {
      // setTimeOut(global.imageTimeOut/1000)
      // setLoading(true)
      let response_img = await fetch(imageURI);
      let data = await response_img.blob();
      let metadata = {
        type: 'image/jpeg'
      };
      let new_file = new File([data], imageName, metadata);
      const response = await postImage(new_file,imageName)
  
      if (response) {
        if (response?.data?.success == 1) {
            var new_pic = process.env.REACT_APP_API_URL + '/' + process.env.REACT_APP_API_FOLDER + '/' + process.env.REACT_APP_BINARY_FOLDER + '/' + 
            imageName.substring(0,4) + '/' + imageName.substring(4,6) + '/' 
              + imageName.substring(6,8) + '/' + imageName;
            return new_pic
        } else {
          var resultMessage = response?.data?.message?.toString();
          console.log('resultMessage', response);
          if (resultMessage !== "File sudah ada di server") {
            // toast.error(resultMessage);
            return false
          } else {
            var new_pic = process.env.REACT_APP_API_URL + '/' + process.env.REACT_APP_API_FOLDER + '/' + process.env.REACT_APP_BINARY_FOLDER + '/' + 
            imageName.substring(0,4) + '/' + imageName.substring(4,6) + '/' 
              + imageName.substring(6,8) + '/' + imageName;
            return new_pic
          }
        }
      } else {
        return false
      }
    }

    const pushNewImage=(files)=>{
        setLoading(true)
        setModalCamera(false)
        setModalVisible(false)
        updateImageProfile(URL.createObjectURL(files),format(new Date(), "yyyyMMdd")+files.name)
    }
  
    const updateImageProfile = async (uri,name) => {
        
      const imageResponse = await sendImage(uri,name)
  
      if (imageResponse === false) {
        setLoading(false)
        return 
      }else{
        const access_token = getToken()
        var data_user = getUser()
        var user_id = data_user?.user_id ? data_user?.user_id : data_user?.id
        let dataOptions = {access_token: access_token, id: user_id, 
            image: imageResponse, record_status: 'U', updated_by: user_id};
      
          // setTimeOut(global.timeOut/1000)
          setLoading(true)
          const response = await postData(process.env.REACT_APP_BACKEND_URL+'/update_user', dataOptions)
          setLoading(false)
      
          if (response) {
            if (response?.data?.success == 1) {
              toast.success('Gambar profil sudah tersimpan');
              const data_user = JSON.parse(localStorage.getItem('data_user'));
              const new_data = {...data_user,user_image:imageResponse};
                localStorage.setItem('data_user', JSON.stringify(new_data));
            } else {
              var resultMessage = response?.data?.message?.toString();
              console.log('resultMessage', resultMessage);
              toast.error(resultMessage);
            }
          }
      }
    }

    return( 
        <>
            {props?.params?.headless ? <></> : <RenderHeader params={props.params} handleToggleSidebar={handleToggleSidebar}/>}
            <Loader loading={loading} timeOut={60}/>
            <Sidebar backgroundColor='white' collapsed={props.collapsed} toggled={true} collapsedWidth={0} style={{ height: "100vh",position:'absolute' }}>
                <Menu style={{background:"linear-gradient(180deg, #e94da9, #e548a5, #d50580, #af0368, #CA0178)",height:"45%",color:"white"}}>
                        <img onClick={() => openModalImage()} src={data_user?.user_image ? data_user?.user_image : LogoPic} width={"80%"} style={{borderRadius:15,border:"4px solid white",marginTop:25,objectFit:'cover',height:200}}/>
                        <p className='sidebar-text'>{data_user?.name}</p>
                        <p className='sidebar-text'>{data_user?.position}</p>
                </Menu>
                <Menu>
                    <hr style={{width:"80%",marginRight:'auto',marginLeft:'auto'}}></hr>
                    <MenuItem style={location.pathname == '/' || location.pathname == '/riwayat' ? styles.sidebarButtonActive : styles.sidebarButton} onClick={() => {
                        navigate('/')
                        handleToggleSidebar()
                        }}>Halaman Utama</MenuItem>
                    {/* <MenuItem style={location.pathname == '/outlet/registrasi' ? styles.sidebarButtonActive : styles.sidebarButton} onClick={() => {
                        navigate('/outlet/registrasi')
                        handleToggleSidebar()
                        }}>Registrasi Outlet</MenuItem> */}
                    <MenuItem style={location.pathname == '/kepegawaian' ? styles.sidebarButtonActive : styles.sidebarButton} onClick={() => {
                        navigate('/kepegawaian')
                        handleToggleSidebar()
                        }}>Kepegawaian</MenuItem>
                    <MenuItem style={styles.sidebarButton} onClick={() => setModalLogout(true)}>Keluar</MenuItem>
                    <hr style={{width:"80%",marginRight:'auto',marginLeft:'auto'}}></hr>
                    <p onClick={() => updateSystem()} style={{color:"grey",fontSize:11}}>New ARM Mobile versi {process.env.REACT_APP_VERSION}</p>
                </Menu>
            </Sidebar>
            <Modal isOpen={modalLogout} toggle={() => setModalLogout(false)} onClosed={() => setModalLogout(false)} centered>
                <ModalBody>
                    <h3>{process.env.REACT_APP_NAME}</h3>
                    <p>Apakah Anda yakin akan keluar?</p>
                </ModalBody>
                <ModalFooter>
                    <button className='btn-logout-white' onClick={() => setModalLogout(false)}>Tidak</button>
                    <button className='btn-logout-red' onClick={() => Logout()}>Ya</button>
                </ModalFooter>
            </Modal>
            <Modal isOpen={modalVisible} onClosed={() => setModalVisible(false)} centered toggle={() => setModalVisible(false)} className='modal-gambar'>
                    <button className='btn-red' onClick={() => fileInputRef.current.click()}>FOTO DARI GALERI</button>
                    <input
                        ref={fileInputRef}
                        type="file"
                        name="imageURI"
                        onChange={(event) =>
                            pushNewImage(event.target.files[0])
                        }
                        style={{display:'none'}}
                    />
                    <button className='btn-red' onClick={() => setModalCamera(true)}>FOTO DARI KAMERA</button>
                    <Modal isOpen={modalCamera} onClosed={() => setModalCamera(false)} centered toggle={() => setModalCamera(false)} fullscreen className='modal-gambar'>
                        <Webcam
                            audio={false}
                            ref={webcamRef}
                            screenshotFormat="image/jpeg"
                            videoConstraints={videoConstraints}
                            minScreenshotWidth={640}
                            minScreenshotHeight={'100%'}
                            screenshotQuality={1}
                            label="wow"
                            style={{
                            position: "absolute",
                            zindex: 888,
                            left:0,
                            right:0,
                            height: "90vh",
                            width: "100%",
                            objectFit: "cover",
                            }}
                        />
                        <div className='group-camera-buttons'>
                            <div className='camera-button' onClick={() => setModalCamera(false)}>
                                <p style={{color:"black"}}>BATAL</p>
                            </div>
                            <div className='camera-button'>
                                <img onClick={() => takeAPic()} src={capturePic} width={70} height={70}/>
                            </div>
                            <div className='camera-button'>
                                <img onClick={() => switchCamera(!cameraPosition)} src={flipPic} width={110} height={60}/>
                            </div>
                        </div>
                    </Modal>
            </Modal>
        </>
    )
}

export default RenderSidebar