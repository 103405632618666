import '../../App.css';
import React,{useEffect, useState} from "react";
import { useNavigate,useLocation } from "react-router-dom";

import defaultPic from '../../Assets/Images/default_pic.png';
import KartuNama from '../../Assets/Images/kartu-nama.png';
import StockIcon from '../../Assets/Images/pengecekan-stok.png';
import PenjualanIcon from '../../Assets/Images/penjualan.png';
import SurveyIcon from '../../Assets/Images/survey.png';
import PerbandinganIcon from '../../Assets/Images/perbandingan-harga.png';
import DokumentasiIcon from '../../Assets/Images/dokumentasi-umum.png';
import PemajanganIcon from '../../Assets/Images/pemajangan.png';

import shareIcon from '../../Assets/Icons/share-w.png';
import backIcon from '../../Assets/Icons/back.png';
import { getOutletId, getToken, getUser, setOutletId } from '../../Helpers/auth-helper';
import { Modal,ModalBody,ModalFooter } from 'reactstrap';
import { postData } from '../../Helpers/api-helper';
import ViewImages from '../../Components/ViewImages';
import Loader from '../../Components/Loader';

function Outlet(props){
    
	const navigate = useNavigate();
    const {state} = useLocation();
    const data_user = getUser()

    const {data_outlet,gpsLatitude,gpsLongitude,canCekIn,hrStatus,isBack} = state;
    const [modalCheckIn,setModalCheckIn] = useState(false)
    const [modalMessage,setModalMessage] = useState('')
    const [modalStatus,setModalStatus] = useState(0)
    const [loading, setLoading] = useState(false);
    const [timeOut, setTimeOut] = useState(process.env.REACT_APP_TIMEOUT_IMG/1000);
    // const [hrStatus, setHrStatus] = useState(global.hrStatus);
    const [hrStatusDesc, setHrStatusDesc] = useState(global.hrStatusDesc);
    const [checkInImage, setCheckInImage] = useState(data_outlet?.check_in_image ?? global.check_in_image);
    const [modalVisible, setModalVisible] = useState(false);
    const outlet_visit_id = getOutletId()

    const refreshView = () => {
      global.activeScreen = 'OutletScreen';
      console.log('hrStatus',hrStatus)
      console.log('hrStatus',global.hrStatus)
    //   setHrStatus(global.hrStatus);
      setHrStatusDesc(global.hrStatusDesc);
      console.log('global.canCekIn', global.canCekIn);
      console.log(data_outlet?.outlet_visit_id,"outlet_visit_id")
      global.hrStatusDesc = "Check Out"
      global.check_in_image = data_outlet?.check_in_image ?? global.check_in_image
      if (hrStatus === 'CEKIN' && !isBack) {
        handleCheckIn();
        global.hrStatusDesc = "Check In"
      }else if(hrStatus === 'CEKOUT'){
        if(data_outlet?.outlet_visit_id && data_outlet.outlet_visit_id !== null){
            setOutletId(data_outlet.outlet_visit_id)
            global.outlet_visit_id = data_outlet.outlet_visit_id
        }
      }
    }

    useEffect(() => {
        refreshView()
        console.log(gpsLatitude,gpsLongitude,"gpsLatitude,gpsLongitude")
    },[])


    const handleCheckIn = async () => {
      console.log(state,"gpsLatitude,gpsLongitude");
      console.log(canCekIn,"canCekIn");
        
      if (canCekIn == false) {
        setModalMessage('Tidak bisa CEK IN karena belum CEK OUT di outlet lain')
        setModalStatus(1)
      } else if (localStorage.getItem('stored_location_free') == 1 || data_outlet?.distance <= data_outlet?.max_outlet_distance) {
        setModalMessage('Apakah Anda akan Cek In di Outlet '+ data_outlet?.outlet.toUpperCase()+'?')
        setModalStatus(3)
        
      } else {
        if (data_outlet?.distance < 4*data_outlet?.max_outlet_distance) {
            setModalMessage('Tidak bisa CEK IN karena jarak lokasi lebih dari '+ 
            data_outlet?.max_outlet_distance + 'm dari outlet\n\n' + 
            'Apakah akan kalibrasi lokasi outlet?\n\n' + 
            'Proses kalibrasi tercatat di sistem, lakukan jika memang Anda yakin ada dekat outlet')
            setModalStatus(2)
        } else {
            setModalMessage('Tidak bisa CEK IN karena jarak lokasi lebih dari ' + data_outlet?.max_outlet_distance + 'm dari outlet')
            setModalStatus(1)
        }
      }
      setTimeout(() => setModalCheckIn(true),50)
    }

    const handleCheckOut = async () => {
      if (localStorage.getItem('stored_location_free') == 0 && data_outlet?.check_out_check_max_outlet_distance == 'Y' && data_outlet?.distance > data_outlet?.max_outlet_distance) {
        if (data_outlet?.distance < 4*data_outlet?.max_outlet_distance) {
          setModalMessage('Tidak bisa CEK OUT karena jarak lokasi lebih dari '+ 
          data_outlet?.max_outlet_distance + 'm dari outlet\n\n' + 
          'Apakah akan kalibrasi lokasi outlet?\n\n' + 
          'Proses kalibrasi tercatat di sistem, lakukan jika memang Anda yakin ada dekat outlet')
          setModalStatus(2)
        } else {
          setModalMessage('Tidak bisa CEK OUT karena jarak lokasi lebih dari ' + data_outlet?.max_outlet_distance + 'm dari outlet')
          setModalStatus(1)
        }
        setTimeout(() => setModalCheckIn(true),50)
      }else{
        var access_token = getToken()
        var data_user = getUser()
        var user_id = data_user?.user_id ? data_user?.user_id : data_user?.id
        var status = global.hrStatus ? global.hrStatus : hrStatus
        let dataOptions = {access_token: access_token, outlet_name: data_outlet?.outlet, 
            outlet_id: data_outlet?.outlet_id, status: status ? status : 'CEKOUT', 
            user_id: user_id, outlet_visit_id: outlet_visit_id, 
            latitude: gpsLatitude, longitude: gpsLongitude, 
            distance: data_outlet?.distance};
      
        setLoading(true);
          const response = await postData(process.env.REACT_APP_BACKEND_URL+'/add_outlet_visit', dataOptions)
          setLoading(false);
          
          if (response) {
            if (response?.data?.success == 1) {
                localStorage.removeItem('outlet_id')
                navigate('/')
            } else {
              var resultMessage = response?.data?.message?.toString();
              console.log('resultMessage', resultMessage);
              setModalMessage(resultMessage)
              setModalStatus(4)
              setTimeout(() => setModalCheckIn(true),50)
            }
          }
      }
    }

    const [menus,setMenus] = useState(
        [
            {
                name:"Stok Barang",
                icon:StockIcon,
                url:'/outlet/pemesanan',
                transaction_type:'STOCK'
            },{
                name:"Penjualan dan Pemesanan",
                icon:PenjualanIcon,
                url:'/outlet/pemesanan',
                transaction_type:'SALES'
            },{
                name:"Survey",
                icon:SurveyIcon,
                url:'/outlet/survey'
            },{
                name:"Perbandingan Harga",
                icon:PerbandinganIcon,
                url:'/outlet/pemesanan',
                transaction_type:'PRCMP'
            },{
                name:"Dokumentasi Umum",
                icon:DokumentasiIcon,
                url:'/outlet/dokumentasi'
            },{
                name:"Pemajangan Produk",
                icon:PemajanganIcon,
                url:'/outlet/pemajangan'
            }
        ]
    )

    const checkIn=()=>{
        // modalCheckIn(false)
        navigate('/outlet/kamera', { state: {data_outlet: data_outlet,gpsLatitude:gpsLatitude,gpsLongitude:gpsLongitude}})
    }

    return(
        <div className='page-background'>
        <Modal isOpen={modalCheckIn} onClosed={() => setModalCheckIn(false)} centered>
            <ModalBody>
                <h3>{process.env.REACT_APP_NAME}</h3>
                <p>{modalMessage}</p>
            </ModalBody>
            <ModalFooter>
                {modalStatus == 1 && <button className='btn-logout-red' onClick={() => navigate('/')}>OK</button>}
                {modalStatus == 2 && <>
                        <button className='btn-logout-white' onClick={() => navigate('/')}>Tidak</button>
                        <button className='btn-logout-red' onClick={() => {
                            data_outlet.not_calibrate = false
                            navigate('/outlet/tambah', { state: { data_outlet: data_outlet,notCalibrate:false,firstLatitude:gpsLatitude,firstLongitude:gpsLongitude,transaction_type:'KALIBRASI',address_outlet:data_outlet?.address } })
                        }}>Ya</button>
                </>}
                {modalStatus == 3 && <>
                    <button className='btn-logout-white' onClick={() => navigate('/')}>Tidak</button>
                    <button className='btn-logout-red' onClick={() => checkIn(data_outlet,gpsLatitude,gpsLongitude)
                    }>Ya</button>
                </>}
                {modalStatus == 4 && <button className='btn-logout-red' onClick={() => setModalCheckIn(false)}>OK</button>}
            </ModalFooter>
        </Modal>
        <Loader loading={loading} timeOut={60}/>
        <ViewImages setModalVisible={setModalVisible} modalVisible={modalVisible} img_src={checkInImage ? checkInImage : defaultPic}/>
            <div className='outlet-card'>
                <div className='d-flex'>
                    <div className='outlet-img-profile' onClick={() => setModalVisible(true)}>
                        <img src={checkInImage ? checkInImage : defaultPic} width={"90%"} height={120} style={{borderRadius:15,objectFit:'cover'}}/>
                    </div>
                    <div className='card-text' style={{paddingTop:0}}>
                        <div className='card-title'>
                            {data_user?.name}
                        </div>
                        <div className='card-title'>
                            {data_outlet?.outlet}
                        </div>
                        <div className='card-desc'>
                            {data_outlet?.address}
                        </div>
                    </div>
                </div>
            </div>

            <div className='outlet-menus'>
                {
                    menus.map((data,index) => 
                        <div className='outlet-button-menus' onClick={() => navigate(data.url,{state : {data_outlet:data_outlet,outlet_transaction_id:data_outlet?.outlet_transaction_id,transaction_type:data.transaction_type,outlet_id:data_outlet?.outlet_id}})}>
                            <img src={data.icon} width={'60%'} style={{margin:'auto'}}/>
                            <p>{data.name}</p>
                        </div>
                    )
                }
            </div>
            <div>
                <button className='outlet-checkout-button' onClick={() => handleCheckOut()}>{hrStatus == "CEKIN" ? "Check In" : "Check Out"}</button>
            </div>
        </div>
    )
}

export default Outlet