
import React, { useCallback, useEffect, useState } from "react";
import { useGeolocated } from "react-geolocated";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import { createCustomEqual } from "fast-equals";
import { isLatLngLiteral } from "@googlemaps/typescript-guards";
import greenMarker from '../Assets/Icons/map-pin-g.png'
import redMarker from '../Assets/Icons/map-pin-r.png'
import focusIcon from '../Assets/Icons/focus-3-line.png'
import plusIcon from '../Assets/Icons/plus-w.png'
import { Fade } from "reactstrap";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";

  const render = (status: Status) => {
    return <h1>{status}</h1>;
  };

  const GoogleMaps = (props) => {
    const navigate = useNavigate()
    const [zoom, setZoom] = useState(17); // initial zoom
    const [center, setCenter] = useState<google.maps.LatLngLiteral>({
      lat:props?.lat || 0,
      lng:props?.lng || 0
    });
    const [current_location, setCurrentLocation] = useState({
      lat: 0,
      lng: 0,
    });

    const { coords } =
    useGeolocated({
        positionOptions: {
            enableHighAccuracy: true,
        },
        watchPosition: true,
        watchLocationPermissionChange: false,
    });

    useEffect(() => {
      startGeoLocation()
    },[coords?.latitude])

    const startGeoLocation = useCallback(() => {
      const onSuccess = (position) => {
        setCurrentLocation({
          lat: position.coords.latitude,
          lng: position.coords.longitude
        })
        global.current_lat = position.coords.latitude
        global.current_lng = position.coords.longitude
        // setKeyMap(key_map+1)
        console.log(position,"Position")
      }
  
      const onError = (error) => {
        console.log(error.message)
      }
      navigator.geolocation.getCurrentPosition(onSuccess, onError,{enableHighAccuracy:true});
    }, []);

    const onIdle = (m: google.maps.Map) => {
      var new_center = m.getCenter()!.toJSON()
      setCenter(new_center)
      setZoom(m.getZoom()!);
    };
    
    return (
      <div style={{ display: "flex", height: "100%" }}>
        { current_location.lat != 0 &&
          <Wrapper apiKey={process.env.REACT_APP_GOOGLE_KEY_API} render={render}>
          <Map
            disableDefaultUI={true}
            disableDoubleClickZoom={true}
            center={center}
            onIdle={onIdle}
            zoom={zoom}
            minZoom={16}
            maxZoom={30}
            style={{ flexGrow: "1", height: "100%" }}
            currentLocation={current_location}
            keyboardShortcuts={false}
            gestureHandling="greedy"
          >
          {props.outlets &&
            props.outlets?.map((marker, index) => (
              <Marker 
                icon={greenMarker}
                key={index} position={{ 
                lat: marker?.latitude,
                lng: marker?.longitude
              }}/>
          ))}
          {current_location && 
              <Marker 
                icon={redMarker}
                key={1} position={current_location}/>}
            {current_location && 
            <DotCurrentLoc
                // icon={blueDot}
                position={current_location}
            />}
            {current_location && 
            <RadiusCurrentLoc
                // icon={blueDot}
                position={current_location}
            />}
          </Map>
        </Wrapper>}
      </div>
    );
  }
  
  interface MapProps extends google.maps.MapOptions {
    style: { [key: string]: string };
    onClick?: (e: google.maps.MapMouseEvent) => void;
    onIdle?: (map: google.maps.Map) => void;
    children?: React.ReactNode;
  }
  
  const Map: React.FC<MapProps> = ({
    onClick,
    onIdle,
    children,
    style,
    ...options
  }) => {
    const ref = React.useRef<HTMLDivElement>(null);
    const [map, setMap] = React.useState<google.maps.Map>();
  
    React.useEffect(() => {
      if (ref.current && !map) {
        var new_map = new window.google.maps.Map(ref.current, {})
        // Append the control to the DIV.

        // Create the DIV to hold the control.
        const centerControlDiv = document.createElement("div");
        const centerControl = controlButton(new_map);
        centerControlDiv.appendChild(centerControl);
        new_map.controls[google.maps.ControlPosition.TOP_RIGHT].push(centerControlDiv);
        setMap(new_map);
      }
    }, [ref, map]);
  
    // because React does not do deep comparisons, a custom hook is used
    // see discussion in https://github.com/googlemaps/js-samples/issues/946
    useDeepCompareEffectForMaps(() => {
      if (map) {
        map.setOptions(options);
      }
    }, [map, options]);
  
    React.useEffect(() => {
      if (map) {
        ["click", "idle"].forEach((eventName) =>
          google.maps.event.clearListeners(map, eventName)
        );
  
        if (onClick) {
          map.addListener("click", onClick);
        }
  
        if (onIdle) {
          map.addListener("idle", () => onIdle(map));
        }
      }
    }, [map, onClick, onIdle]);

    const controlButton=(map)=>{
      // Create the control.

      const controlButton = document.createElement("button");
      // Set CSS for the control.
      controlButton.style.backgroundColor = "#fff";
      controlButton.style.border = "2px solid #fff";
      controlButton.style.borderRadius = "3px";
      controlButton.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
      controlButton.style.color = "rgb(25,25,25)";
      controlButton.style.cursor = "pointer";
      // controlButton.style.fontFamily = "Roboto,Arial,sans-serif";
      controlButton.style.fontSize = "16px";
      controlButton.style.lineHeight = "38px";
      controlButton.style.margin = "8px 5px 22px";
      controlButton.style.padding = "0 5px";
      controlButton.style.textAlign = "center";
      controlButton.style.opacity = "0.6";
      controlButton.textContent = '';
      controlButton.innerHTML='<img src="' + focusIcon + '" />'
      controlButton.title = "Click to recenter the map";
      controlButton.type = "button";
      // Setup the click event listeners: simply set the map to Chicago.
      controlButton.addEventListener("click", () => {
        console.log(options)
        map.setCenter({
          lat:global.current_lat,
          lng:global.current_lng
        });
      });

      return controlButton
    }
  
    return (
      <>
        <div ref={ref} style={style} />
        {React.Children.map(children, (child) => {
          if (React.isValidElement(child)) {
            // set the map prop on the child component
            // @ts-ignore
            return React.cloneElement(child, { map });
          }
        })}
      </>
    );
  };
  
  const DotCurrentLoc: React.FC<google.maps.MarkerOptions> = (options) => {
    const [marker, setMarker] = React.useState<google.maps.Marker>();
  
    React.useEffect(() => {
      if (!marker) {
        setMarker(new google.maps.Marker({
          position: options.position,
          sName: "Marker Name",
          icon: {
              path: google.maps.SymbolPath.CIRCLE,
              scale: 7,
              fillColor: "#0078FF",
              fillOpacity: 1,
              strokeWeight: 1,
              strokeColor:"white",
              strokeOpacity:1
          },
      }));
      }
  
      // remove marker from map on unmount
      return () => {
        if (marker) {
          marker.setMap(null);
        }
      };
    }, [marker]);
  
    React.useEffect(() => {
      if (marker) {
        marker.setOptions(options);
      }
    }, [marker, options]);
  
    return null;
  };
  
  const RadiusCurrentLoc: React.FC<google.maps.MarkerOptions> = (options) => {
    const [marker, setMarker] = React.useState<google.maps.Marker>();
  
    React.useEffect(() => {
      if (!marker) {
        setMarker(new google.maps.Marker({
          position: options.position,
          sName: "Marker Name",
          icon: {
              path: google.maps.SymbolPath.CIRCLE,
              scale: 35,
              fillColor: "#0078FF",
              fillOpacity: 0.2,
              strokeWeight: 1,
              strokeColor:"#0078FF",
              strokeOpacity:0.7
          },
      }));
      }
  
      // remove marker from map on unmount
      return () => {
        if (marker) {
          marker.setMap(null);
        }
      };
    }, [marker]);
  
    React.useEffect(() => {
      if (marker) {
        marker.setOptions(options);
      }
    }, [marker, options]);
  
    return null;
  };
  
  const Marker: React.FC<google.maps.MarkerOptions> = (options) => {
    const [marker, setMarker] = React.useState<google.maps.Marker>();
  
    React.useEffect(() => {
      if (!marker) {
        setMarker(new google.maps.Marker());
      }
  
      // remove marker from map on unmount
      return () => {
        if (marker) {
          marker.setMap(null);
        }
      };
    }, [marker]);
  
    React.useEffect(() => {
      if (marker) {
        marker.setOptions(options);
      }
    }, [marker, options]);
  
    return null;
  };

  const deepCompareEqualsForMaps = createCustomEqual(
    (deepEqual) => (a: any, b: any) => {
      if (
        isLatLngLiteral(a) ||
        a instanceof google.maps.LatLng ||
        isLatLngLiteral(b) ||
        b instanceof google.maps.LatLng
      ) {
        return new google.maps.LatLng(a).equals(new google.maps.LatLng(b));
      }
  
      // TODO extend to other types
  
      // use fast-equals for other objects
      return deepEqual(a, b);
    }
  );
  
  function useDeepCompareMemoize(value: any) {
    const ref = React.useRef();
  
    if (!deepCompareEqualsForMaps(value, ref.current)) {
      ref.current = value;
    }
  
    return ref.current;
  }
  
  function useDeepCompareEffectForMaps(
    callback: React.EffectCallback,
    dependencies: any[]
  ) {
    React.useEffect(callback, dependencies.map(useDeepCompareMemoize));
  }

export default GoogleMaps