import backIcon from '../Assets/Icons/back.png';
import shareIcon from '../Assets/Icons/share-w.png';
import { useLocation, useNavigate } from 'react-router-dom';
import ViewImages from './ViewImages';
import { useEffect, useState,useCallback } from 'react';
import { getUser } from '../Helpers/auth-helper';

const RenderHeader=(props)=>{
    const navigate = useNavigate(props.params?.title)
    const {state} = useLocation()
    const sidebarImg = 'https://raw.githubusercontent.com/AboutReact/sampleresource/master/drawerWhite.png';
    const [modalVisible,setModalVisible] = useState(false)
    const [title,setTitle] = useState(props.params?.title == 'dynamic' ? document.title : props.params?.title)

    const iconPushed=(type)=>{
        if(type){
            props.handleToggleSidebar()
        }else{
            if(props.params?.back){
                if(props.params?.back == 'outlet'){
                    const {data_outlet} = state
                    navigate('/outlet', { state: { data_outlet: data_outlet } })
                }else{
                    navigate(props.params?.back)
                    console.log('back params')
                }
            }else{
                navigate(-1)
                console.log('back')
            }
        }
    }

    useEffect(() => {
        var new_title = props.params?.title
        if(props.params?.title == 'dynamic'){

            const {data_outlet,transaction_type,outlet_transaction_id} = state;
            var role_for_promotor = localStorage.getItem('role_for_promotor');
            var data_user = getUser();
            global.transaction_type = transaction_type
            if (global.transaction_type !== 'STOCK' && global.transaction_type !== 'PRCMP' && global.transaction_type !== 'TAMBAH' && global.transaction_type !== 'KALIBRASI') {
                if (data_user.role.toUpperCase().includes(role_for_promotor.toUpperCase())) {
                    global.transaction_type = 'SALES';
                } else {
                    global.transaction_type = 'ORDER';
                }
            }
            var location = ""
            if (outlet_transaction_id === undefined) {
                location = " di toko";
            } else if (data_outlet?.outlet_visit_id === undefined) {
                location = " tidak di toko";
            }
            console.log(transaction_type,"transaction_type")
            if (global.transaction_type == 'SALES') {
                new_title = "Penjualan" + location
            } else if (global.transaction_type == 'ORDER') {
                new_title = "Pemesanan" + location
            }else if (global.transaction_type == 'PRCMP') {
                new_title = "Perbandingan Harga"
            } else if(global.transaction_type == 'STOCK'){
                new_title = "Stok" + location
            } else if(global.transaction_type == 'KALIBRASI'){
                new_title = "Kalibrasi Outlet"
            } else if(global.transaction_type == 'TAMBAH'){
                new_title = "Tambah Outlet"
            }
        }
        else if(props.params?.title == 'dynamic-detail'){

            const {transaction_type} = state;
            var role_for_promotor = localStorage.getItem('role_for_promotor');
            var data_user = getUser();
            global.transaction_type = transaction_type
            if (global.transaction_type !== 'STOCK' && global.transaction_type !== 'PRCMP' && global.transaction_type !== 'TAMBAH' && global.transaction_type !== 'KALIBRASI') {
                if (data_user.role.toUpperCase().includes(role_for_promotor.toUpperCase())) {
                    global.transaction_type = 'SALES';
                } else {
                    global.transaction_type = 'ORDER';
                }
            }
            if (global.transaction_type == 'SALES') {
                new_title = "Detail Penjualan"
            } else if (global.transaction_type == 'ORDER') {
                new_title = "Detail Pemesanan"
            }
        }
        setTitle(new_title)
    },[props.params?.title])

    return(
        <div className='header d-flex'>
            {modalVisible && <ViewImages setModalVisible={setModalVisible} modalVisible={modalVisible} img_src={global.check_in_image}/>}
            <img onClick={() => iconPushed(props.params?.mainpage)} src={props.params?.mainpage ? sidebarImg : backIcon} width={30} height={30} style={{marginTop:'auto',marginBottom:'auto',marginRight:15}}/>
            <h3 onClick={() => iconPushed(props.params?.mainpage)} style={{marginTop:'auto',marginBottom:'auto',marginRight:'auto'}}><b>{title}</b></h3>
            {props.params?.share && <img src={shareIcon} width={30} height={30} style={{marginTop:'auto',marginBottom:'auto',marginRight:15}} onClick={() => setModalVisible(true)}/>}
        </div>
    )
}

export default RenderHeader