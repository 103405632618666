import '../../App.css';
import React,{useState,useEffect} from "react";
import { useNavigate } from "react-router-dom";
import toast,{Toaster} from 'react-hot-toast'

import defaultPic from '../../Assets/Images/default_pic.png';
import backIcon from '../../Assets/Icons/back.png';
import starIcon from '../../Assets/Icons/star-p.png';
import folderIcon from '../../Assets/Icons/folder-p.png';
import walletIcon from '../../Assets/Icons/wallet-p.png';
import ViewImages from '../../Components/ViewImages';
import greenMarker from '../../Assets/Icons/map-pin-g.png'
import redMarker from '../../Assets/Icons/map-pin-r.png'
import { getUser } from '../../Helpers/auth-helper';
import { getData, postData } from '../../Helpers/api-helper';

    
function DaftarOutlet(props){
    const navigate = useNavigate();
    const [dataList, setDataList] = useState();
    const [loading, setLoading] = useState(true);
    const [timeOut, setTimeOut] = useState(process.env.REACT_APP_TIMEOUT_IMG/1000);
    const [tmp_search, set_tmp_search] = useState();
    const [search, set_search] = useState();
    const [outlet_id,setOutlet_id] = useState("")
    
    useEffect(() => {
      const timeOutId = setTimeout(() => set_search(tmp_search), 500);
      return () => clearTimeout(timeOutId);
    }, [tmp_search]);
  
    const refreshView = () => {
      global.activeScreen = 'OutletAllScreen';
      console.log('OutletAllScreen');
      getOutlet().then (items => setDataList(items));
    }
      
    useEffect(() => {
        refreshView();
    }, []);
  
    useEffect(() => {
      refreshView()
    }, [search]);
  
    const getOutlet = async () => {
        var data_user = getUser()
        var user_id = data_user?.user_id ? data_user?.user_id : data_user?.id
        let dataOptions = {access_token: global.token, id: user_id, search: search};
    
        // setLoading(true)
        const response = await getData(process.env.REACT_APP_BACKEND_URL+'/outlet_by_all', dataOptions)
        setLoading(false)
    
        if (response) {
            if (response?.data?.success == 1) {
                var result_set = response.data.data;
                global.outlet_id = props?.outlet_id;
                console.log(response.data.data)
                setOutlet_id(props?.outlet_id)
                return (result_set);
            } else {
                var resultMessage = response?.data?.message?.toString();
                console.log('resultMessage', resultMessage);
                toast.error(resultMessage);
            }
        }
    } 

    return(
        <div className='page-background'>
            <div className='SO-page'>
                <input className='form-input-icon input-icon-search' placeholder='Cari Outlet' onChange={(e) => set_tmp_search(e.target.value)}/>
              <div className='wrap-scroll' style={{height:'80vh'}}>
                <div className='scroll-content'>
                {
                    dataList && dataList.map((item,index) => 
                    <OutletList url={'/outlet/pemesanan'}  priority={item?.priority} list={false} outlet_transaction_id={item?.outlet_transaction_id} data_outlet={item} visit_status={item?.visit_status} outlet_id={item?.outlet_id || outlet_id} image={item?.image} outlet={item?.outlet} address={item?.address} distance_desc={item?.distance_desc} max_outlet_distance={item?.max_outlet_distance} distance={item?.distance}/>
                )}
                </div>
            </div>
            </div>
        </div>
    )
}

export const OutletList=(item)=>{
    const data_user = getUser()
    const navigate = useNavigate();
    const canCekIn = item?.canCekIn
    const firstLatitude = item?.gpsLatitude
    const firstLongitude = item?.gpsLongitude

    const [modalVisible, setModalVisible] = useState(false);
    const [imgSRC, setIMGSRC] = useState('');

    const showImages=(img_src)=>{
        setIMGSRC(img_src)
        setModalVisible(true)
    }

    const setOutletTransactionID = (item, id) => {
        item.outlet_transaction_id = id
        global.outlet_transaction_id = id
        console.log('setOutletTransactionID')
        navigate('/outlet/pemesanan', { state: { item: item,transaction_type:'SALES',outlet_transaction_id:id,outlet_id:item?.outlet_id } })
    }
    
    const getOutletTransactionID = async (item) => {
        var user_id = data_user?.user_id ? data_user?.user_id : data_user?.id
        let dataOptions = {access_token: global.token, outlet_id: item?.outlet_id, 
          user_id: user_id};
    
        const response = await postData(process.env.REACT_APP_BACKEND_URL+'/add_outlet_transaction_without_visit', dataOptions)
          
        if (response) {
            if (response?.data?.success == 1) {
                return response.data.outlet_transaction_id
            } else {
                var resultMessage = response?.data?.message?.toString();
                console.log('resultMessage', resultMessage);
            }
        }
    }
    
    const getItem = (item) => {
        console.log('OutletRow getItem item', item);
    
        if (item?.must_check_in === 0) {
            if (item?.outlet_transaction_id === null) {
                getOutletTransactionID(item).then (id => setOutletTransactionID(item, id));
            } else {
                global.outlet_transaction_id = item?.outlet_transaction_id
                item.outlet_transaction_id = item?.outlet_transaction_id
                console.log('outlet_transaction_id',item?.outlet_transaction_id)
                navigate('/outlet/pemesanan', { state: { item: item,transaction_type:'SALES',outlet_transaction_id:item?.outlet_transaction_id,outlet_id:item?.outlet_id } })
            }
        } else {
            global.isBack = false;
            if (item?.visit_status === "CEKIN") {
                global.hrStatus = "CEKOUT";
                global.hrStatusDesc = "Check Out";
            } else {
                global.hrStatus = "CEKIN";
                global.hrStatusDesc = "Check In";
            }
            navigate('/outlet', { state: { data_outlet: item,gpsLatitude:firstLatitude,gpsLongitude:firstLongitude,canCekIn:canCekIn,hrStatus:global.hrStatus,isBack:global.isBack } })
        }
    }
    return(
        <div className={item?.visit_status ?
            (item?.visit_status === null || item?.visit_status === "CEKOUT") ? 'cards' : 'cards-active'
            : (item?.priority === 1) ? 'cards' : 'cards-active'}>
        <ViewImages setModalVisible={setModalVisible} modalVisible={modalVisible} img_src={imgSRC}/>
            <div className='d-flex'>
                <div className='card-img-riwayat' onClick={() => showImages((item?.image !== null && item?.image !== '') ? item?.image : defaultPic)}>
                    <img style={{borderRadius:12,objectFit:'cover'}} width={120} height={80} src={(item?.image !== null && item?.image !== '') ? item?.image : defaultPic}/>
                </div>
                <div className='card-text' onClick={() => getItem(item?.data_outlet)}>
                    <div className='card-title' style={{fontSize:16,fontWeight:"bold"}}>
                        {item?.outlet}
                    </div>
                    <div className='card-desc' style={{fontSize:10,fontWeight:"bold"}}>
                        {item?.address}
                    </div>
                    {item?.distance_desc && (
                    <div className='card-icons-beranda'>
                        <div className='card-item'>
                            <img src={localStorage.getItem('stored_location_free') == 1 || item?.distance <= item?.max_outlet_distance ? greenMarker : redMarker} width={18} height={18}/>
                            <p style={{marginBottom:0,fontSize:12}}>
                                {item?.distance_desc}
                            </p>
                        </div>
                    </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default DaftarOutlet