import '../../../App.css';
import React,{useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import toast,{Toaster} from "react-hot-toast";

import defaultPic from '../../../Assets/Images/default_pic.png';
import loginIcon from '../../../Assets/Icons/login-p.png';
import logoutIcon from '../../../Assets/Icons/logout-p.png';
import calendarIcon from '../../../Assets/Icons/calendar-p.png';
import timerIcon from '../../../Assets/Icons/timer-p.png';
import { getData } from '../../../Helpers/api-helper';
import { getToken, getUser } from '../../../Helpers/auth-helper';
import ViewImages from '../../../Components/ViewImages';
import Loader from '../../../Components/Loader';

function RiwayatKunjungan(){
    
	const navigate = useNavigate();
    const [dataList, setDataList] = useState();
    const [loading, setLoading] = useState(true);
    const [timeOut, setTimeOut] = useState(process.env.REACT_APP_TIMEOUT_IMG/1000);
    const [modalVisible, setModalVisible] = useState(false);
    const [imgSRC, setIMGSRC] = useState('');

    useEffect(() => {
        getStoreVisitHistory().then (items => setDataList(items));
    },[])

    const getStoreVisitHistory = async () => {
        const access_token = getToken()
        const data_user = getUser()
        let dataOptions = {access_token: access_token, user_id: data_user.user_id};
    
        setLoading(true)
        const response = await getData(process.env.REACT_APP_BACKEND_URL+'/outlet_visit_by_user', dataOptions)
        // setLoading(false)
           
        if (response) {
            setLoading(false)
          if (response?.data?.success == 1) {
            var result_set = response.data.data;
    
            return (result_set);
          } else {
            var resultMessage = response?.data?.message?.toString();
            console.log('resultMessage', resultMessage);
            toast.error(resultMessage);
          }
        }else{
            setLoading(false)
        }
    } 

    const intervalToLevels = (interval, levels) => {
        const cbFun = (d, c) => {
          let bb = d[1] % c[0],
            aa = (d[1] - bb) / c[0];
          aa = aa > 0 ? aa + c[1] : '';
      
          return [d[0] + aa, bb];
        };
      
        let rslt = levels.scale.map((d, i, a) => a.slice(i).reduce((d, c) => d * c))
          .map((d, i) => ([d, levels.units[i]]))
          .reduce(cbFun, ['', interval]);
        return rslt[0];
    };
      
    const TimeLevels = {
    scale: [24, 60, 60, 1],
    units: ['d ', 'h ', 'm ', 's ']
    };
    
    const secondsToString = interval => intervalToLevels(interval, TimeLevels);

    const showImages=(img_src)=>{
        setIMGSRC(img_src)
        setModalVisible(true)
    }

    return(
        <div className='page-background'>
        <Loader loading={loading} timeOut={60}/>
            <ViewImages setModalVisible={setModalVisible} modalVisible={modalVisible} img_src={imgSRC}/>
            <div className='wrap-scroll' style={{height:'100vh',paddingBottom:130}}>
                <div className='scroll-content'>
                {   dataList && dataList.map((item,index) => 
                    <div className='cards' key={index+1}>
                        <div className='d-flex'>
                            <div className='card-img-riwayat' onClick={() => showImages((item?.check_in_image !== null && item?.check_in_image !== '') ? item?.check_in_image : defaultPic)}>
                                <img style={{borderRadius:12,objectFit:'cover'}} width={110} height={80} src={(item?.check_in_image !== null && item?.check_in_image !== '') ? item?.check_in_image : defaultPic}/>
                            </div>
                            <div className='card-text'>
                                <div className='card-title'>
                                    {item?.outlet}
                                </div>
                                <div className='card-desc' style={{fontSize:10,fontWeight:600}}>
                                    {item?.address}
                                </div>
                                <div className='card-icons'>
                                    <div className='card-item'>
                                        <img src={calendarIcon} width={18} height={18}/>
                                        <p>{item?.check_in_time !== null && item?.check_in_time !== '' && item?.check_in_time !== '1970-01-01 07:00:00' ? item?.check_in_time.substring(0,10) : 'Belum Cek In'}</p>
                                    </div>
                                    <div className='card-item'>
                                        <img src={timerIcon} width={18} height={18}/>
                                        <p>{item?.visit_duration !== null && item?.visit_duration !== '' && item?.visit_duration !== '0' ? secondsToString(item?.visit_duration) : 'N/A'}</p>
                                    </div>
                                    <div className='card-item'>
                                        <img src={loginIcon} width={18} height={18}/>
                                        <p>{item?.check_in_time !== null && item?.check_in_time !== '' && item?.check_in_time !== '1970-01-01 07:00:00' ? item?.check_in_time.substring(10,19) : 'Belum Cek In'}</p>
                                    </div>
                                    <div className='card-item'>
                                        <img src={logoutIcon} width={18} height={18}/>
                                        <p>{item?.check_out_time !== null && item?.check_out_time !== '' && item?.check_out_time !== '1970-01-01 07:00:00' ? item?.check_out_time.substring(10,19) : 'On Going'}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                </div>
            </div>
        </div>
    )
}

export default RiwayatKunjungan