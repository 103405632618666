import { removeSession } from "../Helpers/auth-helper";

    export const checkCamera=async()=>{
        navigator.permissions.query({
            name: 'camera'
          }).then(function(result) {
            if (result.state === 'denied') {
              sessionStorage.setItem('camera_permission','no')
              removeSession()
            }else if(result.state === 'prompt'){
                sessionStorage.setItem('camera_permission','pending')
            }else if(result.state === 'granted'){
                sessionStorage.setItem('camera_permission','yes')
            }

            const constraints = {
                video: true
            };

            navigator.mediaDevices.getUserMedia(constraints);

            // This will still work for Chrome
            result.onchange = function() {
                if (result.state === 'denied') {
                    sessionStorage.setItem('camera_permission','no')
                    removeSession()
                }else if(result.state === 'prompt'){
                    sessionStorage.setItem('camera_permission','pending')
                }else if(result.state === 'granted'){
                    sessionStorage.setItem('camera_permission','yes')
                }
                window.location.reload()
            }
          })
    }

    export const checkLocation=async()=>{
        navigator.permissions.query({
            name: 'geolocation'
          }).then(function(result) {
              
            const onLocationFetchSuccess = (position) => {
            //   toast.success('Lokasi/Gps di izinkan')
              sessionStorage.setItem('location_permission','yes')
            };
        
            const onLocationFetchFailure = (error = {}) => {
              // Error code 1 corresponds to user denying/blocking the location permission
              if (error.code === 1) {
                // Respond to failure case as required
                // toast.error('Lokasi/Gps tidak di izinkan')
                sessionStorage.setItem('location_permission','no')
              }
            };
        
            navigator.geolocation.getCurrentPosition(onLocationFetchSuccess, onLocationFetchFailure);
        
            if (result.state === 'denied') {
              onLocationFetchFailure();
              sessionStorage.setItem('location_permission','no')
              removeSession()
            }else if(result.state === 'prompt'){
                sessionStorage.setItem('location_permission','pending')
            }else if(result.state === 'granted'){
                sessionStorage.setItem('location_permission','yes')
            }
        
            // This will still work for Chrome
            result.onchange = function() {
                if (result.state === 'denied') {
                    onLocationFetchFailure();
                    sessionStorage.setItem('location_permission','no')
                    removeSession()
                }else if(result.state === 'prompt'){
                    sessionStorage.setItem('location_permission','pending')
                }else if(result.state === 'granted'){
                    sessionStorage.setItem('location_permission','yes')
                }
                window.location.reload()
            }
          })
    }

    export const askCamera=()=>{
        const permissions = navigator.mediaDevices.getUserMedia({video: true})
        permissions.then((stream) => {
            console.log(stream)
            return true
        })
        .catch((err) => {
            removeSession()
            return false
        });
    }