import '../../../App.css';
import React,{useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import toast,{Toaster} from "react-hot-toast";

import defaultPic from '../../../Assets/Images/default_pic.png';
import folderIcon from '../../../Assets/Icons/folder-p.png';
import timeLineIcon from '../../../Assets/Icons/time-line-p.png';
import attachmentIcon from '../../../Assets/Icons/attachment-p.png';
import { getToken, getUser } from '../../../Helpers/auth-helper';
import { getData } from '../../../Helpers/api-helper';
import ViewImages from '../../../Components/ViewImages';
import Loader from '../../../Components/Loader';

function RiwayatKepegawaian(){
    
	const navigate = useNavigate();
    const [dataList, setDataList] = useState();
    const [loading, setLoading] = useState(true);
    const [timeOut, setTimeOut] = useState(process.env.REACT_APP_TIMEOUT_IMG/1000);
    const [modalVisible, setModalVisible] = useState(false);
    const [imgSRC, setIMGSRC] = useState('');

    useEffect(() => {
        getTransactionHistory().then (items => setDataList(items));
    },[])

    const getTransactionHistory = async () => {
        const access_token = getToken()
        const data_user = getUser()
        let dataOptions = {access_token: access_token, user_id: data_user.user_id};
    
        setLoading(true)
        const response = await getData(process.env.REACT_APP_BACKEND_URL+'/human_capital_transaction_by_user', dataOptions)
    
        if (response) {
            setLoading(false)
            if (response?.data?.success == 1) {
            var result_set = response.data.data;
    
            return (result_set);
            } else {
            var resultMessage = response?.data?.message?.toString();
            console.log('resultMessage', resultMessage);
            toast.error(resultMessage);
            }
        }else{
            setLoading(false)
        }
    } 

    const showImages=(img_src)=>{
        setIMGSRC(img_src)
        setModalVisible(true)
    }

    return(
        <div className='page-background'>
        <Loader loading={loading} timeOut={60}/>
        <ViewImages setModalVisible={setModalVisible} modalVisible={modalVisible} img_src={imgSRC}/>
            <div className='wrap-scroll' style={{height:'100vh',paddingBottom:130}}>
                <div className='scroll-content'>
                {   dataList && dataList.map((item,index) => 
                    <div key={index+1} className='cards'>
                        <div className='d-flex'>
                            <div className='card-img-riwayat' onClick={() => showImages((item?.event_image !== null && item?.event_image !== '') ? item?.event_image : defaultPic)}>
                                <img style={{borderRadius:12,objectFit:'cover'}} width={110} height={80} src={(item?.event_image !== null && item?.event_image !== '') ? item?.event_image : defaultPic}/>
                            </div>
                            <div className='card-text'>
                                <div className='card-title'>
                                    {item?.event_name}
                                </div>
                                <div className='card-desc' style={{fontSize:10,fontWeight:600}}>
                                    {item?.event_description}
                                </div>
                                <div className='card-icons'>
                                    <div className='card-item'>
                                    {item?.approval_by !== null && (
                                        <img src={attachmentIcon} width={18} height={18}/>
                                    )}
                                        <p>{item?.approval_by}</p>
                                    </div>
                                    <div className='card-item'>
                                        <img src={timeLineIcon} width={18} height={18}/>
                                        <p>{item?.event_date}</p>
                                    </div>
                                    <div className='card-item'>
                                        <img src={folderIcon} width={18} height={18}/>
                                        <p>{item?.approval_status}</p>
                                    </div>
                                    <div className='card-item'>
                                        <p>{item?.approval_reason}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                </div>
            </div>
        </div>
    )
}

export default RiwayatKepegawaian