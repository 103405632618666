import '../../App.css';
import React,{useState,useEffect} from "react";
import { getUser } from "../../Helpers/auth-helper";
import md5 from "md5";
import { useNavigate } from 'react-router-dom';
import HomeIconW from '../../Assets/Icons/homepage-w.png';

function Dashboard(){

    const [url,setUrl] = useState("")
    const navigate = useNavigate()

    useEffect(() => {
        const data_user = getUser()
        var url = process.env.REACT_APP_ARM_WEB + data_user.user_name + "/" + md5(data_user.password)
        // var url = "https://guguskarangmekar.com/new_arm_beta_web/#/login/naufal/e10adc3949ba59abbe56e057f20f883e"
        setUrl(url)
        console.log(url,"URL")
        // window.screen.orientation.lock('landscape');
    },[])

    const goHome = () => {
        navigate('/');
    }

    return(
    <>
    <button className='btn-home-dashboard' onClick={()=> goHome()}><img src={HomeIconW} width={25} height={25}/></button>
        <iframe src={url} style={{position:"relative", top:0, left:0, bottom:0, right:0, width:"100%", height:"100%", border:"none", margin:0, padding:0, overflow:"hidden", zIndex:"999999"}}>
            Your browser doesn't support iframes
        </iframe></>
    );
}

export default Dashboard