// Retrieve user data from session storage
export const getUser = () => {
    const user = localStorage.getItem('data_user')
    return user ? JSON.parse(user) : null
}

// Set token and user to browser storage
export const setUserSession = (user) => {
    // localStorage.setItem('token', token)    
    localStorage.setItem('data_user', JSON.stringify(user))
}

// Retrieve token from local storage
export const getToken = () => {
    return localStorage.getItem('token') || null
}

// Remove token and user from browser storage
export const removeSession = () => {
    localStorage.removeItem('token')
    localStorage.removeItem('data_user')
    localStorage.removeItem('user')
    localStorage.removeItem('role_for_promotor')
    localStorage.removeItem('max_location_distance')
    localStorage.removeItem('checkin_image')
    localStorage.removeItem('stored_location_free')
    localStorage.removeItem('system_identity_key')
}

// Set token and user to browser storage
export const setToken = (token) => {
    global.token = token
    localStorage.setItem('token', token)
}

// Set token and user to browser storage
export const setPermission = (permission) => {
    sessionStorage.setItem('permission', permission)
}

export const getPermission = () => {
    const permission = sessionStorage.getItem('permission')
    return permission
}

// Set token and user to browser storage
export const setOutletId = (outlet_id) => {
    localStorage.setItem('outlet_id', outlet_id)
}

export const getOutletId = () => {
    const outlet_id = localStorage.getItem('outlet_id')
    return outlet_id
}

// Update user from session storage
export const updateUser = (name, phone) => {
    const user = JSON.parse(sessionStorage.getItem('data_user'))

    if (user) {
        user.name = name
        user.phone = phone

        localStorage.removeItem('data_user')
        localStorage.setItem('data_user', user)
    }
}