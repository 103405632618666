import { useCallback, useRef, useState } from "react";
import Webcam from "react-webcam";
import logoAdvan from '../Images/new_logo.png';
import Resizer from "react-image-file-resizer";
import ImageMarker from 'react-image-marker';

const videoConstraints = {
  width: 640,
  height: 480,
  facingMode: { exact: "environment" },
};

  const WebcamCapture = () => {
  const webcamRef = useRef(null);
  const [imgSrc, setImgSrc] = useState(null);
  const watermark = require('watermarkjs')

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    MarkWithText(imageSrc)
  }, [webcamRef, setImgSrc]);

  const MarkWithText = (imageSrc)=>{
    watermark([imageSrc])
    .image(watermark.text.lowerLeft('Prototype Advan', '12px Josefin Slab', '#fff', 1))
    .then(function (img) {
      MarkWithImage(img.src)
    });
  }

  const MarkWithImage=(imageSrc)=>{
    watermark([imageSrc, logoAdvan])
    .image((uploadImage, logoAdvan) => {
      const context = uploadImage.getContext('2d')
      const logoResizedHeight = 20
      const logoResizedWidth = 80
      const posX = (uploadImage.width+10) / 2
      const posY = 5

      context.save()

      context.globalAlpha = 1
      context.drawImage(logoAdvan, posX, posY, logoResizedWidth, logoResizedHeight)

      context.restore()
      return uploadImage
    })
    .then(function (watermarkedImage) {
      setImgSrc(watermarkedImage.src)
    });
  }

  // const resizeFile = (file) =>
  //   new Promise((resolve) => {
  //     Resizer.imageFileResizer(
  //       file,
  //       720,
  //       1280,
  //       "JPEG",
  //       100,
  //       0,
  //       (uri) => {
  //         resolve(uri)
  //       },
  //       "base64"
  //     );
  //   });

  return (
    <>
      <Webcam
        audio={false}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        videoConstraints={videoConstraints}
        minScreenshotWidth={200}
        minScreenshotHeight={200}
        screenshotQuality={0.8}
        width={'100%'}
        height={'80%'}
        label="wow"
      />
      <button onClick={capture}>Capture Photo</button>
      {/* {imgSrc && imgSrc.map((data,index) => 
        <p>{data}</p>
      )} */}
      {imgSrc && 
        <img src={imgSrc} alt="img" />
      }
    </>
  );
};

export default WebcamCapture