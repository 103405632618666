import '../App.css';
import React,{useEffect, useState} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';

import HomeIconG from '../Assets/Icons/homepage-g.png';
import HomeIconW from '../Assets/Icons/homepage-w.png';
import HistoryIconG from '../Assets/Icons/history-g.png';
import HistoryIconW from '../Assets/Icons/history-w.png';
import DashboardIconG from '../Assets/Icons/report-g.png';
import DashboardIconW from '../Assets/Icons/report-w.png';
import Beranda from '../Pages/Mainpage/Beranda';
import Riwayat from '../Pages/Mainpage/Riwayat';
import Dashboard from '../Pages/Mainpage/Dashboard';

function Footer(){
	const navigate = useNavigate();
    const location = useLocation()
    const [tabIndex, setTabIndex] = useState(0);

    useEffect(() => {
        if(location.pathname == '/'){
            setTabIndex(0)
        }else if(location.pathname == '/riwayat' || location.pathname == '/riwayat/kunjungan' || location.pathname == '/riwayat/kepegawaian'){
            setTabIndex(1)
        }
    },[location.pathname])

    const [pages,setPages] = useState(
        [
            {
                name:"Beranda",
                url:'/',
                key:"beranda",
                iconActive:HomeIconW,
                iconInactive:HomeIconG,
                show:true
            },{
                name:"Riwayat",
                url:'/riwayat',
                key:"riwayat",
                iconActive:HistoryIconW,
                iconInactive:HistoryIconG,
                show:true
            },{
                name:"Dashboard",
                url:'/dashboard',
                key:"dashboard",
                iconActive:DashboardIconW,
                iconInactive:DashboardIconG,
                show:true
            }
        ]
    )

    return(
        <div className='main-footer'>
            {/* <div className={collapsed ? 'main-content' :'main-content blur-content'}> */}
                <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
                    {/* {
                        pages.map((data,index)=>
                            <TabPanel tabIndex={index}>
                                {data.component}
                            </TabPanel>
                        )
                    } */}
                    <div className='footer'>
                        <TabList>
                        {
                            pages.filter(item => item.show).map((data,index)=>
                                <Tab onClick={() => navigate(data.url)}>
                                    <img src={tabIndex == index ? data.iconActive : data.iconInactive} width={25} height={25}/>
                                    <p className='react-tabs__text'>{data.name}</p>
                                </Tab>
                            )
                        }
                        </TabList>
                    </div>
                </Tabs>
        </div>
    )
}

export default Footer