import '../../App.css';
import React,{useState} from "react";
import { useNavigate } from "react-router-dom";
import defaultPic from '../../Assets/Images/default_pic.png';
import kepegawaianIcon from '../../Assets/Images/kepegawaian.png';
import surveyIcon from '../../Assets/Images/survey.png';
import { getUser } from '../../Helpers/auth-helper';


function Riwayat(){
    const navigate = useNavigate()
    const data_user = getUser()
    const [menus,setMenus] = useState(
        [
            {
                name:"KUNJUNGAN",
                icon:surveyIcon,
                url:'/riwayat/kunjungan'
            },{
                name:"KEPEGAWAIAN",
                icon:kepegawaianIcon,
                url:'/riwayat/kepegawaian'
            },{
                name:"APPROVAL",
                icon:surveyIcon,
                url:'/riwayat/approval-outlet'
            }
        ]
    )
    return(
        <div className='page-background'>
            <div className='outlet-card'>
                <div className='d-flex'>
                    <div className='outlet-img-profile'>
                        <img src={data_user?.user_image ? data_user?.user_image : defaultPic} width={"85%"} style={{borderRadius:15,objectFit:'cover',height:100}}/>
                    </div>
                    <div className='card-text'>
                        <div className='card-title'>
                            {data_user?.name}
                        </div>
                        <div className='card-desc'>
                            {data_user?.position}
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className='outlet-menus'>
                {
                    menus.map((data,index) => 
                        <div className='riwayat-button-menus' onClick={() => navigate(data.url)}>
                            <img src={data.icon} width={'70%'} height={100} style={{aspectRatio:1,objectFit:"contain",margin:'auto'}}/>
                            <p>{data.name}</p>
                        </div>
                    )
                }
            </div> */}
            <div className='kepegawaian-menus'>
                {
                    menus.map((data,index) => 
                        <div className='kepegawaian-button-menus' onClick={() => navigate(data.url)}>
                            <img src={data.icon} width={'70%'} height={100} style={{aspectRatio:1,objectFit:"contain",margin:'auto'}}/>
                            <p>{data.name}</p>
                        </div>
                    )
                }
            </div>
            <div>
            </div>
        </div>
    )
}

export default Riwayat