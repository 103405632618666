import '../../App.css';
import React,{useState,useEffect} from "react";
import { useNavigate,useLocation } from "react-router-dom";
import toast,{Toaster} from 'react-hot-toast';

import defaultPic from '../../Assets/Images/default_pic.png';
import backIcon from '../../Assets/Icons/back.png';
import starIcon from '../../Assets/Icons/star-p.png';
import folderIcon from '../../Assets/Icons/folder-p.png';
import walletIcon from '../../Assets/Icons/wallet-p.png';
import searchIcon from '../../Assets/Icons/search-b.png';
import filterRIcon from '../../Assets/Icons/filter-p.png';
import filterBIcon from '../../Assets/Icons/filter-b.png';
import scanQRIcon from '../../Assets/Icons/scan-qr-p.png';
import { getToken, getUser } from '../../Helpers/auth-helper';
import { getData } from '../../Helpers/api-helper';
import Loader from '../../Components/Loader';
import ViewImages from '../../Components/ViewImages';
import { InputGroup, InputGroupText,Input, Modal, ModalBody } from 'reactstrap';
import BarcodeScanner from '../../Components/BarcodeScanner';

function PemesananDanPenjualan(){
    
	const navigate = useNavigate();
    const {state} = useLocation();
    const {data_outlet,transaction_type,outlet_transaction_id,outlet_id} = state;
    const data_user = getUser()

    const [dataList, setDataList] = useState();
    const [loading, setLoading] = useState(true);
    const [timeOut, setTimeOut] = useState(process.env.REACT_APP_TIMEOUT_IMG/1000);
    // const [activeButton, setActiveButton] = useState(global.transaction_type);
    const [modalVisible, setModalVisible] = useState(false)
    const [modalFilter, setModalFilter] = useState(false)
    const [modalBarcode, setModalBarcode] = useState(false)
    const [categories, setCategories] = useState();
    const [brands, setBrands] = useState();
    const [selectedCategory, setSelectedCategory] = useState();
    const [selectedBrand, setSelectedBrand] = useState();
    const [selectedBarcode, setSelectedBarcode] = useState();
    const [tmp_search, set_tmp_search] = useState();
    const [search, set_search] = useState();
    const [imgSRC, setIMGSRC] = useState('');

    useEffect(() => {
        const timeOutId = setTimeout(() => set_search(tmp_search), 500);
        return () => clearTimeout(timeOutId);
    }, [tmp_search]);
    
    useEffect(() => {
        refreshView();
    }, []);

    useEffect(() => {
      refreshView()
    }, [search]);

    const refreshView = () => {
        global.activeScreen = 'SalesAndOrderScreen';
        var location = ""
        if (outlet_transaction_id === undefined) {
            location = " di toko";
        } else if (data_outlet?.outlet_visit_id === undefined) {
            location = " tidak di toko";
        }

        console.log(transaction_type,"transaction_type")

        var role_for_promotor = localStorage.getItem('role_for_promotor');
        var data_user = getUser();
        global.transaction_type = transaction_type
        if (global.transaction_type !== 'STOCK' && global.transaction_type !== 'PRCMP') {
            if (data_user.role.toUpperCase().includes(role_for_promotor.toUpperCase())) {
                global.transaction_type = 'SALES';
            } else {
                global.transaction_type = 'ORDER';
            }
        }
        global.serial_number = ''
        getProduct().then (items => setDataList(items))
        getListParameterGroup('CATEGORY').then (items => setCategories(items));
        getListParameterGroup('BRAND').then (items => setBrands(items));
    }

    const getListParameterGroup = async (group_id) => {
        var access_token = getToken()
        let dataOptions = {access_token: access_token, group_id: group_id};
        
        // setLoading(true)
        const response = await getData(process.env.REACT_APP_BACKEND_URL+'/list_parameter_group', dataOptions)
        setLoading(false)
    
        if (response) {
            if (response?.data?.success == 1) {
            var jsonData = response.data.data;
            console.log(jsonData);
            
            const tmp = [];
            tmp.push( {
                name: '-- All --',
                id: '',
            });
            for (let i = 0; i < jsonData.length; i++) {
                tmp.push( {
                    name: jsonData[i].name,
                    id: jsonData[i].code,
                });
            }
            return tmp;
            } else {
                var resultMessage = response?.data?.message?.toString();
                console.log('resultMessage', resultMessage);
                toast.error(resultMessage);
            }
        }
    }

    const getProduct = async () => {
        var user_id = data_user?.user_id ? data_user?.user_id : data_user?.id
        var access_token = getToken()
        let dataOptions = {access_token: access_token, user_id: user_id, 
        transaction_type: global.transaction_type, 
        reference_id: data_outlet?.outlet_visit_id === undefined || data_outlet?.outlet_visit_id == null ? 
        outlet_transaction_id : data_outlet?.outlet_visit_id,
        reference_type: data_outlet?.outlet_visit_id === undefined || data_outlet?.outlet_visit_id == null ? 'NOTVIS' : 'OUTVIS',
        search: search, category_id: global.category_id, brand_id: global.brand_id, serial_number: global.serial_number};
          
        // setLoading(true)
        const response = await getData(process.env.REACT_APP_BACKEND_URL+'/product_transaction_by_user', dataOptions)
    
        if (response) {
            setLoading(false)
            if (response?.data?.success == 1) {
                var result_set = response.data.data;
                global.outlet_visit_id = data_outlet?.outlet_visit_id;
                global.outlet_transaction_id = outlet_transaction_id;
                global.outlet_id = data_outlet?.outlet_id;
                if(result_set.length == 0){
                    toast.error('Data tidak ditemukan');
                }
                return (result_set);
            } else {
                var resultMessage = response?.data?.message?.toString();
                console.log('resultMessage', resultMessage);
                toast.error(resultMessage);
            }
        }else{
            setLoading(false)
        }
    }

    const showImages=(img_src)=>{
        setIMGSRC(img_src)
        setModalVisible(true)
    }

    const resetFilter=()=>{
        setSelectedBrand([])
        setSelectedCategory([])
        setSelectedBarcode("")
        global.brand_id = ''
        global.category_id = ''
        global.serial_number = ''
        refreshView()
        setModalFilter(false)
        setModalBarcode(false)
    }

    const filterData=()=>{
        refreshView()
        setModalFilter(false)
    }

    const onSelectFilter=(name,value)=>{
        console.log(value)
        if(name == 'brands'){
            global.brand_id = value
            setSelectedBrand(value)
        }
        if(name == 'categories'){
            global.category_id = value
            setSelectedCategory(value)
        }
    }

    const getItem=(item)=>{
        item.outlet_id = outlet_id
        if(global.transaction_type !== 'STOCK' && global.transaction_type !== 'PRCMP'){
            item.outlet_transaction_id = outlet_transaction_id
            navigate('/outlet/pemesanan/detail', { state: { item: item,transaction_type:global.transaction_type,outlet_transaction_id:data_outlet?.outlet_transaction_id,outlet_visit_id:data_outlet?.outlet_visit_id } })
        } else{
            if(global.transaction_type === 'PRCMP'){
                navigate('/outlet/detail-perbandingan-harga', { state: { item: item,transaction_type:global.transaction_type,outlet_transaction_id:data_outlet?.outlet_transaction_id,outlet_visit_id:data_outlet?.outlet_visit_id } })
            }else{
                navigate('/outlet/detail-stok', { state: { item: item,transaction_type:global.transaction_type,outlet_transaction_id:data_outlet?.outlet_transaction_id,outlet_visit_id:data_outlet?.outlet_visit_id } })
            }
        }
    }

    const numberFormatter = new Intl.NumberFormat('id-ID', {
        style: 'decimal',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    });

    const onNewScanResult = (decodedText, decodedResult) => {
        console.log(decodedText, decodedResult)
        setSelectedBarcode(decodedText)
        global.serial_number = decodedText
        setModalBarcode(false)
        setLoading(true)
        getProduct().then (items => setDataList(items))
    };

    return(
        <div className='page-background'>
           
            <Loader loading={loading} timeOut={60}/>
            <ViewImages setModalVisible={setModalVisible} modalVisible={modalVisible} img_src={imgSRC}/>
            <div className='SO-page'>
                <div className='wrap-input'>
                    <InputGroup>
                        <InputGroupText className='input-icon-strap'>
                            <img src={searchIcon} width={18} height={18}/>
                        </InputGroupText>
                        <Input placeholder="Cari Produk" className='input-icon-strap' onChange={(e) => set_tmp_search(e.target.value)}/>
                        <InputGroupText className='input-icon-strap' onClick={() => setModalBarcode(true)}>
                            <img src={scanQRIcon} width={25} height={25}/>
                        </InputGroupText>
                        <InputGroupText className='input-icon-strap' onClick={() => setModalFilter(true)}>
                            <img src={selectedBrand || selectedCategory ? filterRIcon : filterBIcon} width={18} height={18}/>
                        </InputGroupText>
                    </InputGroup>
                </div>
                <div className='wrap-scroll'>
                    <div className='scroll-content'>
                        {
                            dataList && dataList.map((item,index)=>
                                <div key={index}  className={item?.entry_date == null ? 'cards' : 'cards-active'}>
                                    <div className='d-flex'>                                
                                        <div className='card-img-riwayat' onClick={() => showImages((item?.product_image !== null && item?.product_image !== '') ? item?.product_image : defaultPic)}>
                                            <img style={{borderRadius:12,objectFit:'cover'}} width={110} height={80} src={(item?.product_image !== null && item?.product_image !== '') ? item?.product_image : defaultPic}/>
                                        </div>
                                        <div className='card-text' onClick={() => getItem(item)}>
                                            <div className='card-title'>
                                                {item?.product}
                                            </div>
                                            <div className='card-desc' style={{fontWeight:600}}>
                                                {item?.storage_type}
                                            </div>
                                            <div className='card-item' style={{height:20}}>
                                                <img src={walletIcon} width={18} height={18}/>
                                                <p style={{fontWeight:700}}>
                                                    {'Rp' + numberFormatter.format(item?.price_in_a_unit)}
                                                </p>
                                            </div>
                                            <div className='card-icons-2 d-flex' style={{marginRight:10}} onClick={() => navigate('/outlet/pemesanan/detail', { state: { item: item } })}>
                                                <div style={{marginRight:'auto'}}>

                                                </div>
                                                <div className='card-item'>
                                                    <img src={folderIcon} width={18} height={18}/>
                                                    <p style={{fontWeight:700}}>{item?.brand}</p>
                                                </div>
                                                <div className='card-item'>
                                                    <img src={starIcon} width={18} height={18}/>
                                                    <p style={{fontWeight:700}}>{item?.category}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
            <Modal isOpen={modalFilter} size='sm' toggle={() => setModalFilter(false)} onClosed={() => setModalFilter(false)} centered>
                <ModalBody>
                    <h3 style={{textAlign:'center'}}><b>Filter</b></h3>
                    
                    <select className='form-select-1' onChange={(e) => onSelectFilter('categories',e.target.value)} value={selectedCategory}>
                        <option value={''} selected disabled>Pilih Kategori</option>
                        {
                            categories && categories.map((item,index) =>
                                <option value={item.id}>{item.name}</option>
                            )
                        }
                    </select>&nbsp;
                    <select className='form-select-1' onChange={(e) => onSelectFilter('brands',e.target.value)} value={selectedBrand}>
                        <option value={''} selected disabled>Pilih Brand</option>
                        {
                            brands && brands.map((item,index) =>
                                <option value={item.id}>{item.name}</option>
                            )
                        }
                    </select>
                    <div className="btn-group-modal-footer">
                        <button className="btn-modal-footer" style={{marginRight:'auto'}} onClick={() => resetFilter()}>RESET</button>
                        <button className="btn-modal-footer" onClick={() => filterData()}>FILTER</button>
                    </div>
                </ModalBody>
            </Modal>
            <Modal isOpen={modalBarcode} size='sm' toggle={() => setModalBarcode(false)} onClosed={() => setModalBarcode(false)} centered>
                <ModalBody>
                    <h3 style={{textAlign:'center'}}><b>Scan Barcode</b></h3>
                    <BarcodeScanner
                        fps={10}
                        qrbox={250}
                        disableFlip={false}
                        qrCodeSuccessCallback={onNewScanResult}
                    />
                    <div className="btn-group-modal-footer">
                        <button className="btn-modal-footer" style={{marginRight:'auto',marginLeft:'auto'}} onClick={() => resetFilter()}>RESET</button>
                    </div>
                </ModalBody>
            </Modal>
        </div>
    )
}

export default PemesananDanPenjualan