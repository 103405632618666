import React,{useEffect, useState, useRef} from "react";
import { getOutletId, getUser } from "../../Helpers/auth-helper";
import md5 from "md5";
import { useNavigate,useLocation,useNavigationType  } from 'react-router-dom';
// import { useBackListener } from "../../Components/useBackListener";

const Survey = (props)=>{

    const [url,setUrl] = useState("")

    useEffect(() => {
        const data_user = getUser()
        const user_id = data_user?.user_id ? data_user?.user_id : data_user?.id
        const outlet_visit_id = getOutletId()
        var url = process.env.REACT_APP_ARM_TAKE_SURVEY + user_id + "/" + data_user.user_name + "/" + md5(data_user.password) + "/" + outlet_visit_id
        // var url = 'https://guguskarangmekar.com/new_arm_beta_web/#/take-survey/324021911160001/naufal/e10adc3949ba59abbe56e057f20f883e/124031813090001'
        setUrl(url)
        console.log(url,"URL")
    },[])

    // useBackListener(({ location }) => {
    //     console.log("Navigated Back", { location });
    //     navigate("/outlet", { state: { data_outlet: data_outlet } });
    // });

    const navigate = useNavigate();
    const {state} = useLocation()
    const {data_outlet} = state

    const location = useLocation();
    const navType = useNavigationType();

    useEffect(() => {
        if (navType === "POP" && location.key !== "default") {
            navigate("/outlet", { state: { data_outlet: data_outlet } });
          }
          console.log(navType,location.key,"location.key")
      }, [ navigate ])

    return(
    <>
        <iframe src={url} style={{position:"relative", top:0, left:0, bottom:0, right:0, width:"100%", height:"100%", border:"none", margin:0, padding:0, overflow:"hidden", zIndex:"999999"}}>
            Your browser doesn't support iframes
        </iframe></>
    );
}

export default Survey