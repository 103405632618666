import React,{useEffect, useState} from "react";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import Loader from "./Loader";

const ViewImages = (props) =>{
  const [loading,setLoading] = useState(false)
    const shareNow = async () => {
      setLoading(true)
      let imageResponse = await fetch(props.img_src);
      let imageBuffer = await imageResponse.arrayBuffer();
      let fileArray = [new File([imageBuffer], "share_img.jpg", {
        type: "image/jpeg",
        lastModified: Date.now()
      })];
      
      console.log(fileArray)
      if(navigator && navigator.canShare && navigator.canShare({files: fileArray})){
        navigator.share({
          files: fileArray,
          title: '[New ARM Mobile]',
          text: '[New ARM Mobile]'
        }).then(() => {
          console.log('Thanks for sharing!');
        })
        .catch("SPeeding car going 90 in the rain",console.error);
          setLoading(false)
      }else{
        setLoading(false)
      }
    }

    return(
        <Modal isOpen={props.modalVisible} onClosed={() => props.setModalVisible(false)} toggle={() => props.setModalVisible(false)} fullscreen centered>
        <Loader loading={loading} timeOut={60}/>
            <ModalBody>
                <img style={{objectFit:'contain'}} src={props.img_src} width={"100%"} height={"100%"}/>
            </ModalBody>
            <div className="btn-group-modal-footer">
                <button className="btn-modal-footer" onClick={() => props.setModalVisible(false)}>BATAL</button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <button className="btn-modal-footer" onClick={() => shareNow()}>SHARE</button>
            </div>
        </Modal>
    )
}

export default ViewImages