import React, {useState, useEffect} from 'react'
import {requestForToken, onMessageListener} from './Firebase';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { getUser } from '../Helpers/auth-helper';

const Notification = () => {
    const [notification, setNotification] = useState({title: '', body: ''});
    const data_user = getUser()
    if(data_user){
      // const user_id = data_user?.user_id ? data_user?.user_id : data_user?.id
      // requestForToken(user_id);
    }

    onMessageListener()
        .then((payload) => {
            console.log(payload,"Received Message")
            toast(payload?.notification?.title + " " + payload?.notification?.body, {
                duration: 4000,
                position: 'top-center',
              
                // Styling
                style: {},
                className: '',
              
                // Custom Icon
                icon: '🔔',
              
                // Change colors of success/error/loading icon
                iconTheme: {
                  primary: '#000',
                  secondary: '#fff',
                },
              
                // Aria
                ariaProps: {
                  role: 'status',
                  'aria-live': 'polite',
                },
              });
            setNotification({title: payload?.notification?.title, body: payload?.notification?.body});
        })
        .catch((err) => console.log('failed: ', err));


}

export default Notification