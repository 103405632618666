import '../../App.css';
import React,{useEffect, useState,useRef,useCallback} from "react";
import { useNavigate,useLocation } from "react-router-dom";
import toast,{Toaster} from "react-hot-toast";
import DatePicker from 'react-date-picker';
import { format, differenceInDays, } from "date-fns";
import "../../../node_modules/react-date-picker/dist/DatePicker.css";
import 'react-calendar/dist/Calendar.css';

import { getData, postData, postImage } from '../../Helpers/api-helper';
import Loader from '../../Components/Loader';
import { getToken, getUser, removeSession } from '../../Helpers/auth-helper';
import addImagePic from '../../Assets/Images/add_image_gallery.png';
import capturePic from '../../Assets/Images/capture.png';
import flipPic from '../../Assets/Images/flip.png';
import { Modal, ModalBody } from 'reactstrap';
import Webcam from 'react-webcam';

const DetailKepegawaian=()=>{

    const fileInputRef=useRef();
    const webcamRef = useRef(null);
    const navigate = useNavigate()
    const {state} = useLocation();
    const access_token = getToken()

    const { page_type, title } = state;

    const [type,setType] = useState("3")
    const [dataList, setDataList] = useState();
    const [item, set_item] = useState();
    const [loading, setLoading] = useState(true);
    const [timeOut, setTimeOut] = useState(process.env.REACT_APP_TIMEOUT_IMG/1000);
    const [fromDate, setFromDate] = useState(new Date());
    const [fromDateOpen, setFromDateOpen] = useState(false);
    const [toDate, setToDate] = useState(new Date());
    const [toDateOpen, setToDateOpen] = useState(false);
    const [notes, set_notes] = useState();
    const [showTransactionMaster, setShowTransactionMaster] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [modalCamera, setModalCamera] = useState(false);
    const [isPermitted, setIsPermitted] = useState(true);
    const [picture, setPicture] = useState('');
    const [cameraPosition, switchCamera] = useState(true);
    const [selected_list,set_selected_list] = useState("");
    
    const [imageURI, setImageURI] = useState('');
    const [imageName, setImageName] = useState('');
    const [imageFile, setImageFile] = useState('');
    const [openFromDate, setOpenFromDate] = useState(false);
    const [openToDate, setOpenToDate] = useState(false);
    // const appState = useRef(AppState.currentState);

    const videoConstraints = {
        width: 640,
        height: 480,
          facingMode: { exact: cameraPosition ? 'environment' : 'user' },
      };

    useEffect(() => {
        getGetTransactionMaster().then (items => setDataList(items));
        // console.log(data_user,"Data user")
        // startCamera()
    },[])

    // const startCamera=async()=>{

    //   checkCamera().then(async(res) => {
    //     console.log(res.state,"camera_permit")
    //     if(res.state == 'denied'){
    //       removeSession()
    //       navigate('/login')
    //       window.location.reload();
    //     }else if(res.state == 'prompt'){
    //       const permissions = navigator.mediaDevices.getUserMedia({video: true})
    //       permissions.then((stream) => {
    //           console.log(stream)
    //           return true
    //       })
    //       .catch((err) => {
    //           removeSession()
    //           navigate('/login')
    //           window.location.reload();
    //       });
    //     }
    //   }
    //   ).catch((e) => 
    //     console.log(e,"camera_permit")
    //   )
    // }

    function addHours(date, hours) {
        date.setHours(date.getHours() + hours);
    
        return date;
    }

    const takeAPic = useCallback(() => {
      const imageSrc = webcamRef.current.getScreenshot();
      var imageName = new Date().getTime()+".jpg";
      console.log(imageName)
      setImageURI(imageSrc)
      
      setImageName(format(new Date(), "yyyyMMdd")+imageName)
      setModalCamera(false)
      setModalVisible(false)
    }, [webcamRef, setImageURI]);


    const setItem = (id_description) => {
        const index = dataList.findIndex(item => item.id_description == id_description)
        set_item(dataList[index]);
        set_selected_list(id_description)
    }

    const getItem = (item) => {
        console.log(item);
        set_item(item);
    }

    // const TransactionMasterRow = ({ item }) => (
    //     <div className='form-input'>
    //         <button className='button-input-date' onClick={() => getItem(item)}>{item?.id_description}</button>
    //     </div>
    // );

    const TransactionMasterList = ({ itemList }) => (
        <select className='form-select-3' value={selected_list} onChange={(e) => setItem(e.target.value)}>
            <option default>Pilih Form</option>
            {
                itemList && itemList.map((data,index) =>
                    <option value={data.id_description}>{data?.id_description}</option>
                )
            }
        </select>
    );

    const getGetTransactionMaster = async () => {
        let dataOptions = {access_token: access_token, type:page_type};

        // setTimeOut(global.timeOut/1000)
        // setLoading(true)
        const response = await getData(process.env.REACT_APP_BACKEND_URL+'/human_capital', dataOptions)
        setLoading(false)

        if (response) {
            if (response?.data?.success == 1) {
                var jsonData = response.data.data;
                console.log(jsonData);
                var data_count = response.data.count;
                if (data_count == 1) {
                    setShowTransactionMaster(false);
                    getItem(jsonData[0]);
                } else {
                    setShowTransactionMaster(true);
                }
                return (jsonData);
            } else {
                var resultMessage = response?.data?.message?.toString();
                console.log('resultMessage', resultMessage);
                toast.error(resultMessage);
                toast.error(resultMessage);
            }
        }
    }

    const validateData=async()=>{
      
        setLoading(true)
        const day_count = differenceInDays(new Date(toDate.toDateString()), new Date(fromDate.toDateString())) + 1;
        // console.log('day count', day_count, new Date(toDate.toDateString()), new Date(addHours(fromDate, 7).toDateString()));
        if (item?.sub_type === '' || item?.sub_type === undefined) {
            toast.error('Masukkan pilihan detail dari cuti/sakit')
            setLoading(false)
        }else if (new Date(fromDate.toDateString()) > new Date(toDate.toDateString())) {
            toast.error('Dari Tanggal tidak boleh melebihi Hingga Tanggal')
            setLoading(false)
        }else if ((notes === '' || notes === undefined) && item?.need_reason) {
            toast.error('Masukkan Alasan');
            setLoading(false)
        }else if ((imageName === '' || imageName === undefined) && item?.need_photo) {
            toast.error('Masukkan Photo');
            setLoading(false)
        }else if (day_count > item?.max_day) {
            toast.error('Tidak boleh melebihi ' + item?.max_day + ' hari');
            setLoading(false)
        }else if (item?.need_photo) {
            const imageResponse = await sendImage()
    
            if (imageResponse === false) {
                setLoading(false)
                return
            }else{
                handleSubmitPress(imageResponse)
            }
        }else{
            handleSubmitPress()
        }
    }

    const handleSubmitPress = async (image = picture) => {
        
        const data_user = getUser()
        var user_id = data_user?.user_id ? data_user?.user_id : data_user?.id
        let dataOptions = {access_token: access_token, type: item?.type, sub_type: item?.sub_type, 
          user_id: user_id, created_by: user_id, from_date: fromDate, to_date: toDate, 
          notes: notes, image: image};
        // setTimeOut(global.timeOut/1000)
        const response = await postData(process.env.REACT_APP_BACKEND_URL+'/insert_human_capital_transaction', dataOptions)
    
        if (response) {
            setLoading(false)
          if (response?.data?.success == 1) {
            toast.success('Data sudah tersimpan');
            setTimeout(() =>{
                navigate(-1);
            },500)
          } else {
            var resultMessage = response?.data?.message?.toString();
            console.log('resultMessage', resultMessage);
            toast.error(resultMessage);
          }
        }else{
            setLoading(false)
        }
    }

    const sendImage = async () => {
      // setTimeOut(global.imageTimeOut/1000)
      // setLoading(true)
      console.log("imageFile",imageFile)
      let response_img = await fetch(imageURI);
      let data = await response_img.blob();
      let metadata = {
        type: 'image/jpeg'
      };
      let new_file = new File([data], imageName, metadata);
      const response = await postImage(new_file,imageName)
      setLoading(false)
  
      if (response) {
        if (response?.data?.success == 1) {
            var new_pic = process.env.REACT_APP_API_URL + '/' + process.env.REACT_APP_API_FOLDER + '/' + process.env.REACT_APP_BINARY_FOLDER + '/' + 
            imageName.substring(0,4) + '/' + imageName.substring(4,6) + '/' 
              + imageName.substring(6,8) + '/' + imageName;
            setPicture(new_pic)
            return new_pic
        } else {
          var resultMessage = response?.data?.message?.toString();
          console.log('resultMessage', response);
          if (resultMessage !== "File sudah ada di server") {
            toast.error(resultMessage);
            return false
          } else {
            var new_pic = process.env.REACT_APP_API_URL + '/' + process.env.REACT_APP_API_FOLDER + '/' + process.env.REACT_APP_BINARY_FOLDER + '/' + 
            imageName.substring(0,4) + '/' + imageName.substring(4,6) + '/' 
              + imageName.substring(6,8) + '/' + imageName;
            setPicture(new_pic)
            return new_pic
          }
        }
      } else {
        return false
      }
    }

    const setDate=(name,date)=>{
        if(name == 'fromDate'){
            setFromDate(date)
        }
        if(name == 'toDate'){
            setToDate(date)
        }
    }

    const openModalImage=()=>{
        setModalVisible(true)
        // startCamera()
    }

    return(
        <div className='page-background'>
            <Loader loading={loading} timeOut={60}/>
           
            <div className='detail-page'>
                { showTransactionMaster ? (
                <TransactionMasterList
                    itemList={dataList}
                    />) : <></>}
                <div className='card-hrdetail'>
                    {/* <h3>{title(type)}</h3> */}
                    <h3>{item?.id_description}</h3>
                    <div className='form-input'>
                        <button className='button-input-date' onClick={() => setOpenFromDate(true)}>Dari Tanggal</button>
                        <DatePicker 
                            className="input-date-1" 
                            name="fromDate" 
                            dateFormat="yyyy-MM-dd" 
                            selected={fromDate} 
                            onChange={(date) => setDate('fromDate',date)}
                            isOpen={openFromDate}
                            minDate={new Date()}
                            onCalendarClose={() => setOpenFromDate(false)}
                        />
                        <label>{format(fromDate, "dd MMMM yyyy")}</label>
                    </div>
                    <div className='form-input'>
                        <button className='button-input-date' onClick={() => setOpenToDate(true)}>Hingga Tanggal</button>
                        <DatePicker 
                            className="input-date-1" 
                            name="fromDate" 
                            dateFormat="yyyy-MM-dd" 
                            selected={toDate} 
                            onChange={(date) => setDate('toDate',date)}
                            isOpen={openToDate}
                            minDate={fromDate}
                            onCalendarClose={() => setOpenToDate(false)}
                        />
                        <label>{format(toDate, "dd MMMM yyyy")}</label>
                    </div>
                    
                    {item?.need_reason ? 
                    <div className=''>
                        <textarea className='form-textarea-2' placeholder='Alasan' onChange={(e) => set_notes(e.target.value)}/>
                    </div> : <></>}
                    { item?.need_photo ?
                        <div className='add-image'>
                            <img onClick={() => openModalImage()} src={imageURI ? imageURI : addImagePic} className='add-image-button' width={'100%'} height={150} style={{objectFit:'contain'}}/>
                        </div> : <></>
                    }
                    <button className='outlet-checkout-button' onClick={() => validateData()}>SIMPAN</button>
                </div>
            </div>
            <Modal isOpen={modalVisible} onClosed={() => setModalVisible(false)} centered toggle={() => setModalVisible(false)} className='modal-gambar'>
                    <button className='btn-red' onClick={() => fileInputRef.current.click()}>FOTO DARI GALERI</button>
                    <input
                        ref={fileInputRef}
                        type="file"
                        name="imageURI"
                        onChange={(event) => {
                            console.log(event.target.files[0])
                            setImageURI(URL.createObjectURL(event.target.files[0]))
                            setImageFile(event.target.files[0])
                            setImageName(format(new Date(), "yyyyMMdd")+event.target.files[0].name)
                            setModalVisible(false)
                        }}
                        style={{display:'none'}}
                    />
                    <button className='btn-red' onClick={() => setModalCamera(true)}>FOTO DARI KAMERA</button>
                    <Modal isOpen={modalCamera} onClosed={() => setModalCamera(false)} centered toggle={() => setModalCamera(false)} fullscreen className='modal-gambar'>
                        <Webcam
                            audio={false}
                            ref={webcamRef}
                            screenshotFormat="image/jpeg"
                            videoConstraints={videoConstraints}
                            minScreenshotWidth={640}
                            minScreenshotHeight={'100%'}
                            screenshotQuality={1}
                            label="wow"
                            style={{
                            position: "absolute",
                            zindex: 888,
                            left:0,
                            right:0,
                            height: "90vh",
                            width: "100%",
                            objectFit: "cover",
                            }}
                        />
                        <div className='group-camera-buttons'>
                            <div className='camera-button' onClick={() => setModalCamera(false)}>
                                <p style={{color:"black"}}>BATAL</p>
                            </div>
                            <div className='camera-button'>
                                <img onClick={takeAPic} src={capturePic} width={70} height={70}/>
                            </div>
                            <div className='camera-button'>
                                <img onClick={() => switchCamera(!cameraPosition)} src={flipPic} width={110} height={60}/>
                            </div>
                        </div>
                    </Modal>
            </Modal>
        </div>
    )
}

export default DetailKepegawaian