import '../App.css';
import React,{useState} from "react";
import logo from '../Assets/Images/new_logo.png';
import backIcon from '../Assets/Icons/back.png';
import { useNavigate } from "react-router-dom";
import { getToken } from '../Helpers/auth-helper';
import toast,{Toaster} from "react-hot-toast";
import md5 from "md5";
import { postData } from '../Helpers/api-helper';
import Loader from '../Components/Loader';
import { RedGradient } from '../Components/Gradient';

function UbahPassword(){
    
	const navigate = useNavigate();
    // const [version,setVersion] = useState(sessionStorage.getItem('version'))
    const [version,setVersion] = useState('0.1')
    const [showPassword,setShowPassword] = useState(false)
    const [user_name,setUserName] = useState("")
    const [oldPassword,setOldPassword] = useState("")
    const [newPassword,setNewPassword] = useState("")
    const [confirmPassword,setConfirmPassword] = useState("")
    const [loading,setLoading] = useState(false)

    const validateChangePassword=()=>{
        setLoading(true)
        if (!user_name || user_name === '') {
            toast.error('Masukkan UserID');
            setLoading(false)
        }else if (!oldPassword || oldPassword === '') {
            toast.error('Masukkan Password lama');
            setLoading(false)
        }else if (!newPassword || newPassword === '') {
            toast.error('Masukkan Password baru');
            setLoading(false)
        }else if (!confirmPassword || confirmPassword === '') {
            toast.error('Masukkan Password konfirmasi');
            setLoading(false)
        }else if (oldPassword === newPassword) {
            toast.error('Password baru tidak boleh sama dengan Password lama');
            setLoading(false)
        }else if (newPassword !== confirmPassword) {
            toast.error('Password baru tidak sama dengan Password konfirmasi');
            setLoading(false)
        }else if (/\s/g.test(newPassword) === true) {
            toast.error('Password baru mengandung spasi');
            setLoading(false)
        }else{
            handleSubmitButton()
        }

    }

    const handleSubmitButton = async () => {
        var token = getToken()
        // let tmpResult = passwordStrengthValidation(newPassword);
        // if (tmpResult !== "OK") {
        //   showAlert(tmpResult);
        //   return;
        // }

        let dataOptions = {access_token: token, user_name: user_name, 
            password: md5(oldPassword), new_password: md5(newPassword)};

        const response = await postData(process.env.REACT_APP_BACKEND_URL+'/change_password', dataOptions)

        if (response) {
            setLoading(false)
            if (response?.data?.success == 1) {
            toast.success('Password sudah berubah');
            setTimeout(() => {
                navigate('/login');
            }, 500);
            } else if (response?.data?.success == 0) {
            var resultMessage = response?.data?.message?.toString();
            console.log('resultMessage', resultMessage);
            var errorCode = resultMessage.substring(0, 4);
            console.log(errorCode);
            if (errorCode == "1120") {
                toast.error('Password baru tidak sama dengan Password konfirmasi');
            } else if (errorCode == "1121") {
                toast.error('User ID atau Password salah');
            } else {
                toast.error('Ubah Password gagal');
            }
            } else {
                toast.error('Ubah Password gagal');
            }
        }else{
            setLoading(false)
        }
    }

    return(
        <div className='ubah-p-background'>
            <Loader loading={loading} timeOut={60}/>
            <div className='header d-flex' onClick={() => navigate('/login')}>
                <img src={backIcon} width={30} height={30} style={{marginTop:'auto',marginBottom:'auto',marginRight:15}}/>
                <h3 style={{marginTop:'auto',marginBottom:'auto',marginRight:15}}>Ubah Password</h3>
            </div>
            <div className='ubah-p-logo'>
                <img src={logo} width={"80%"} style={{marginTop:0,verticalAlign:'middle'}}/>
            </div>
            <div className='ubah-p-form'>
                <input type='text' className='form-input-ubah' placeholder='User Name' onChange={(e) => setUserName(e.target.value)}/>
                <input type={showPassword ? 'text' : 'password'} className='form-input-ubah' placeholder='Password lama' onChange={(e) => setOldPassword(e.target.value)}/>
                <input type={showPassword ? 'text' : 'password'} className='form-input-ubah' placeholder='Password baru' onChange={(e) => setNewPassword(e.target.value)}/>
                <input type={showPassword ? 'text' : 'password'} className='form-input-ubah' placeholder='Password konfirmasi' onChange={(e) => setConfirmPassword(e.target.value)}/>
                <button className='outlet-checkout-button' onClick={() => setShowPassword(!showPassword)}><b>{ showPassword? 'TUTUP PASSWORD' : 'LIHAT PASSWORD'}</b></button>
                <button className='outlet-checkout-button' onClick={() => validateChangePassword()} style={{backgroundColor: "linear-gradient("+RedGradient+");" }}><b>UBAH PASSWORD</b></button>
            </div>
        </div>
    )
}

export default UbahPassword