import '../../App.css';
import React,{useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import GoogleMapReact from 'google-map-react';
import { useGeolocated } from "react-geolocated";
import {getToken, getUser, removeSession} from '../../Helpers/auth-helper.js';
import toast,{Toaster} from 'react-hot-toast'
import { format } from "date-fns";

import Logo from '../../Assets/Images/default_pic.png'
import iconMarker from '../../Assets/Icons/attachment-p.png'
import Marker from '../../Components/Marker';
import MoneyIcon from '@mui/icons-material/Money';
import PagesIcon from '@mui/icons-material/Pages';
import plusIcon from '../../Assets/Icons/plus-w.png'
import GoogleMaps from '../../Components/GoogleMaps.tsx';
import { OutletList } from '../Outlet/DaftarOutlet.js';
import { getData } from '../../Helpers/api-helper.js';
import { checkLocation } from '../../Components/Permissions.js';
import { requestForToken } from '../../Components/Firebase.js';

function Beranda(){
	const navigate = useNavigate();
    const [location, setLocation] = useState({ lat: 0, long: 0 });
    const access_token = getToken()
    const data_user = getUser()

    const [dataList, setDataList] = useState(false);
    const [loading, setLoading] = useState(false);
    const [recordCount, setRecordCount] = useState(0);
    const [canCekIn, setCanCekIn] = useState(false);
    const [tmp_search, set_tmp_search] = useState();
    const [search, set_search] = useState();
    const [currentDate, setCurrentDate] = useState('');
    const [realAddress, setRealAddress] = useState('');

    const { coords,isGeolocationAvailable, isGeolocationEnabled } =
    useGeolocated({
        positionOptions: {
            enableHighAccuracy: true,
        },
        watchPosition: true,
        watchLocationPermissionChange: true,
    });
    const [colTambah,setColTambah] = useState(false)

    useEffect(() => {
      if(isGeolocationAvailable){
        if(isGeolocationEnabled){
          startGeoLocation()
          // requestForToken()
          // console.log('called effect')
        }else{
          toast.error('Lokasi/GPS tidak aktif!')
        }
        // var status = checkLocation().then(async(res) => {
        //   console.log(res.state,"location permit")
        //   if(res.state == 'denied'){
        //     // toast('Izin kan penggunaan Lokasi agar dapat lanjut menggunakan aplikasi')
        //     removeSession()
        //     navigate('/login')
        //     // window.location.reload();
        //   }else if(res.state == 'prompt'){
        //     // toast('Izin kan penggunaan Lokasi agar dapat lanjut menggunakan aplikasi')
        //   }
        // }
        // ).catch((e) => 
        //   console.log(e,"location")
        // )
        // console.log(status,"Status geolocatio")
        sessionStorage.setItem('permission', '1')
      }else{
        toast.error('Lokasi/GPS tidak mendukung di browser ini!')
        sessionStorage.setItem('permission', '0')
      }
    },[isGeolocationEnabled])

    useEffect(() => {
      // setNewCenter()
      locationChanged()
    },[coords?.latitude,coords?.longitude])

    const startGeoLocation=()=>{
      const onSuccess = (position) => {
        toast.success('Lokasi terdeteksi!')
        setLocation({
          lat: position.coords.latitude,
          long: position.coords.longitude
        });
        if(position.coords.latitude != 0){
          getStoreByEmployeeCode(position.coords.latitude,position.coords.longitude);
        }
      }
  
      const onError = (error) => {
        // console.log(error.message)
      }
      navigator.geolocation.getCurrentPosition(onSuccess, onError,{enableHighAccuracy:true});
    }

    function distance(lat1, lon1, lat2, lon2, unit) {
      if ((lat1 == lat2) && (lon1 == lon2)) {
        return 0;
      }
      else {
        var radlat1 = Math.PI * lat1/180;
        var radlat2 = Math.PI * lat2/180;
        var theta = lon1-lon2;
        var radtheta = Math.PI * theta/180;
        var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        if (dist > 1) {
          dist = 1;
        }
        dist = Math.acos(dist);
        dist = dist * 180/Math.PI;
        dist = dist * 60 * 1.1515;
        if (unit=="K") { dist = dist * 1.609344 }
        if (unit=="N") { dist = dist * 0.8684 }
        return dist;
      }
    }

    const locationChanged=()=>{
      const onSuccess = (position) => {
        setLocation({
          lat: position.coords.latitude,
          long: position.coords.longitude
        });
        if (global.tmp_latitude === undefined) {
          global.tmp_latitude = 0;
        }
        if (global.tmp_longitude === undefined) {
          global.tmp_longitude = 0;
        }
        var tmp_distance = distance(global.tmp_latitude, global.tmp_longitude, position.coords.latitude, position.coords.longitude, "K") * 1000
        var max_location_distance = localStorage.getItem('max_location_distance')
        // console.log(tmp_distance,"tmp_distance")
        console.log(max_location_distance,"max_location_distance")
        if (tmp_distance > parseInt(max_location_distance)) {
          global.tmp_latitude = position.coords.latitude
          global.tmp_longitude = position.coords.longitude
          getStoreByEmployeeCode(position.coords.latitude,position.coords.longitude);
        }
      }
  
      const onError = (error) => {
        // console.log(error.message)
      }
      navigator.geolocation.getCurrentPosition(onSuccess, onError,{enableHighAccuracy:true});
    }

    const getStoreByEmployeeCode = async (latitude,longitude) => {
        setDataList([])
        var user_id = data_user?.user_id ? data_user?.user_id : data_user?.id
        let paramOptions = {access_token: access_token, id: user_id, longitude: longitude, 
        latitude: latitude, search: search};
        const response = await getData(process.env.REACT_APP_BACKEND_URL+'/outlet_by_user', paramOptions)
        setLoading(false);
        setCurrentDate(format(new Date(), "dd MMMM yyyy HH:mm:ss"));

        if (response) {
            if (response?.data?.success == 1) {
                var result_set = response.data.data;

                setRecordCount(response.data.count)
                if (response.data.count > 0) {
                  (result_set[0].visit_status === null || result_set[0].visit_status === 'CEKOUT') ? setCanCekIn(true) : setCanCekIn(false)
                }
                setDataList(response.data.data)
            } else {
                var resultMessage = response?.data?.message?.toString();
                // console.log('resultMessage', resultMessage);
                setDataList([])
                toast.error(resultMessage)
            }
        }
    } 

    useEffect(() => {
      const timeOutId = setTimeout(() => set_search(tmp_search), 500);
      return () => clearTimeout(timeOutId);
    }, [tmp_search]);

    useEffect(() => {
      refreshView()
      // console.log('called effect 3')
    }, [search]);

    const refreshView = () => {
      if(location.lat != 0){
        // console.log('called effect 4')
        startGeoLocation();
      }
    }

    return(
        <div className='page-background'>
          <div className="tambah-outlet-position" style={{marginTop:!colTambah ? 240 : 54}}>
            <div style={{display:colTambah ? "block" : "none"}}>
              <div className="fade-button-group">
                <div className="fade-button" onClick={() => navigate('/outlet/list')}>
                  <button className="round-button">
                    <MoneyIcon width={5} style={{color:"white"}}/>
                  </button>
                  <p>Menambah Transaksi</p>
                </div>
                <div className="fade-button">
                  <button className="round-button" onClick={() => navigate('/outlet/tambah',{state:{notCalibrate:true,firstLatitude:location.lat,firstLongitude:location.long,transaction_type:'TAMBAH'}})}>
                    {/* <img src={PagesIcon} width={20} height={20} style={{margin:'auto'}}/> */}
                    <PagesIcon style={{color:"white"}}/>
                  </button>
                  <p>Menambah Outlet</p>
                </div>
                <div className="fade-button">
                  <button className="round-button" onClick={() => navigate('/outlet/registrasi',{state:{data_outlet:[]}})}>
                    {/* <img src={PagesIcon} width={20} height={20} style={{margin:'auto'}}/> */}
                    <PagesIcon style={{color:"white"}}/>
                  </button>
                  <p>Registrasi Outlet</p>
                </div>
              </div>
            </div>
          <button className="btn-tambah-outlet d-flex" onClick={() => setColTambah(!colTambah)}>
            <img src={plusIcon} width={20} height={20} style={{margin:'auto'}}/>
              <p style={{margin:'auto'}}>Tambah</p>
          </button>
          </div>
            <input className='form-input-icon input-icon-search' placeholder='Cari Outlet' onChange={(e) => set_tmp_search(e.target.value)}/>
            {isGeolocationAvailable && location.lat != 0 ? 
                <div style={{ height: 240, width: '100%' }}>
                    <GoogleMaps locationChanged={locationChanged} outlets={dataList} lat={location.lat} lng={location.long}/>
                </div> : <></>
            }
            { recordCount > 0 && location.lat != 0 ? 
              <div className='wrap-scroll' style={{height:'100vh',paddingBottom:430}}>
                <div className='scroll-content'>
                    {
                      dataList && dataList.map((item,index) => 
                        <OutletList url={'/outlet'} list={true} visit_status={item?.visit_status} canCekIn={canCekIn} priority={item?.priority} gpsLatitude={location.lat} gpsLongitude={location.long} data_outlet={item} outlet_id={item?.outlet_id} image={item?.image} outlet={item?.outlet} address={item?.address} distance_desc={item?.distance_desc} max_outlet_distance={item?.max_outlet_distance} distance={item?.distance}/>
                    )}
                </div>
            </div> : <></>
            }
            <div className='ghost-footer'>
            </div>
        </div>
    )
}

export default Beranda